<template>
    <div v-if="!item.hide" class="multiple-choice d-flex">
        <div class="d-flex">
            <span
                v-if="item.numbering && item.numbering.number > 0"
                class="preview-number d-flex align-items-start"
                :class="{ 'number-hide': item.numbering.hidden }"
            >
                <NumberFormatIndex :index-info="item.numbering" resizable />
            </span>
        </div>

        <div>
            <span
                class="text-justify d-block multichoice-question"
                :style="{ minHeight: `${style.font_space}em` }"
                v-html="item.data.subtitle"
            ></span>
            <div class="choices ml-2 mt-2" :class="['grid-' + item.data.number_of_columns]">
                <div
                    v-for="(option, index) in itemOptions"
                    :key="`option-${index}`"
                    class="choice d-flex align-items-start"
                    :style="{
                        lineHeight: style.font_space + 'em',
                    }"
                    @click="optionItemClicked(index)"
                >
                    <div class="d-flex align-items-center justify-content-center">
                        <div
                            class="correct-answer-marker rounded-circle d-flex align-items-start justify-content-center"
                            :class="{ 'border-danger': option.correct && document.show_answer_key }"
                        >
                            <span v-if="answerFormat === 7 && option.correct && document.show_answer_key">
                                <b-icon-check variant="danger" />
                            </span>
                            <NumberFormatIndex v-else :index-info="{ number: index + 1, format: answerFormat }" resizable />
                        </div>
                    </div>
                    <div class="p-0 d-flex align-items-center option-answer">
                        <div style="word-break: break-word">{{ option.answer }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import indexToLetter from '../../mixins/IndexToLetter'
import { mapGetters, mapState } from 'vuex'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'

export default defineComponent({
    name: 'MultipleChoicePreview',
    components: { NumberFormatIndex },
    mixins: [indexToLetter],
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            },
        },
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            style: 'document/documentStyle',
        }),
        itemOptions() {
            return this.item.data.hasBooleanOptions ? this.item.data.booleanOptions : this.item.data.options
        },
        answerFormat() {
            return this.item.data.settings?.format || 11
        },
    },
    methods: {
        optionItemClicked(index) {
            this.$emit('optionItemClicked', { ...this.item, ...{ clickedIndex: index } })
        },
    },
})
</script>

<style lang="scss">
.correct-answer-marker {
    border-radius: 50%;
    border: 1px solid transparent;
    height: 100%;
    width: 1.5em;
}

.choice {
    padding-bottom: 0.5em;
}
.option-answer {
    border: 1px solid transparent;
}

.multichoice-question > p {
    margin-bottom: 0;
}
</style>
