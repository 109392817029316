<template>
    <div v-if="!item.hide" id="blank_space" class="d-flex my-auto">
        <div
            v-if="item.data.box_outline === false"
            class="position-relative thisShow"
            :style="{
                height: item.data.height + 'in',
                transition: 'height .5s ease-in-out',
            }"
        >
            <figure
                class="mb-0 border-style"
                :style="{
                    '--bgColor': bgColor,
                }"
                :class="[
                    item.data.height <= 3 ? 'border-style' : '',
                    item.data.height > 3 && item.data.height <= 5 ? 'border-style-middle' : '',
                    item.data.height >= 5 ? 'border-style-large' : '',
                ]"
            >
                <div
                    class="d-block position-absolute"
                    :class="[
                        item.data.height <= 3 ? 'addingBefore' : '',
                        item.data.height > 3 && item.data.height <= 5 ? 'addingAgainBefore' : '',
                        item.data.height >= 5 ? 'addingLastBefore' : '',
                    ]"
                    :style="{ '--heightPseudo': item.data.height / 5 + 'in' }"
                >
                    <img src="../../../img/icons/blank_bottom.svg" alt="" />
                </div>
            </figure>
        </div>

        <div
            v-if="item.data.box_outline === true || is_published"
            class="text-center d-flex ml-auto"
            :style="[
                {
                    height: item.data.height + 'in',
                    width: '100%',
                },
                item.data.box_outline
                    ? { border: `${item.data.border_width + 'px'} ${item.data.line_style} ${item.data.line_color}` }
                    : { border: 'none' },
            ]"
        ></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BlankSpacePreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            bgColor: '#cacaca',
            is_published: window.doc.is_published ?? false,
        }
    },
})
</script>

<style scoped>
.border-style::before {
    content: '';
    border-left: 3px dashed var(--bgColor) !important;
    left: 0.7rem;
    background-image: url('img/icons/blank_border.svg');
    background-repeat: no-repeat;
    position: absolute;
    display: flex;
    height: 100%;
    z-index: -10;
}

.border-style::after {
    content: '';

    transform: rotate(90deg);
    left: -1.7rem;
    width: 100px;
    display: flex;

    position: absolute;
    bottom: 1.5rem;
    height: 0;
    color: #cacaca;
    transition: all cubic-bezier(0, 0.81, 0.4, 1) 0.5s;
}
.border-style-middle::after {
    content: 'BLANK';

    transform: rotate(90deg);
    left: -1.7rem;
    width: 100px;
    display: flex;

    position: absolute;
    bottom: 4.5rem;
    height: 0;
    color: #cacaca;
    transition: all cubic-bezier(0, 0.81, 0.4, 1) 0.5s;
}
.border-style-large::after {
    content: 'BLANK SPACE';

    transform: rotate(90deg);
    left: -1.7rem;
    width: 100px;
    display: flex;

    position: absolute;
    bottom: 8.5rem;
    height: 0;
    color: #cacaca;
    transition: all cubic-bezier(0, 0.81, 0.4, 1) 0.5s;
}

.addingBefore {
    bottom: -0.3rem;
}
.addingAgainBefore {
    bottom: -0.3rem;
}
.addingLastBefore {
    bottom: -0.3rem;
}

.addingBefore::after {
    content: '';
    position: absolute;
    background-image: url('../../../img/icons/blank_border.svg');
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: auto;

    left: 0.2rem;
    width: 25px;
    display: flex;

    bottom: 1rem;
    padding-top: 2rem;
    height: var(--heightPseudo);
}
.addingAgainBefore::after {
    content: '';
    position: absolute;
    background-image: url('../../../img/icons/blank_border.svg');
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: auto;

    left: 0.2rem;
    width: 25px;
    display: flex;

    bottom: 3rem;
    padding-top: 6rem;
    height: var(--heightPseudo);
}
.addingLastBefore::after {
    content: '';
    position: absolute;
    background-image: url('../../../img/icons/blank_border.svg');
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: auto;

    left: 0.2rem;
    width: 25px;
    display: flex;

    bottom: 3rem;
    padding-top: 9rem;
    height: var(--heightPseudo);
}
</style>
