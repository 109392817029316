<template>
    <div class="flashcard">
        <template
            v-for="(page, pageIndex) in flashcard.page_setup.flashcard_print_type === 'single-side'
                ? documents
                : backSidedDocuments"
        >
            <Document
                v-if="flashcard.page_setup.flashcard_type === 'standard'"
                :key="'page-' + pageIndex"
                class="render-page"
                :index="pageIndex"
                :header-height="style.firstPageHeaderHeightInches"
                :cards-count="page.filter((item) => item !== false).length * 2"
                :document-first-page-height="flashcardFirstPageHeight"
                @instructionHeightChanged="instructionHeightChanged"
            >
                <div
                    v-if="page.filter((item) => item !== false).length"
                    class="flashcard-container"
                    :style="{ 'padding-top': '8px' }"
                >
                    <img class="scissors" src="../../../img/scissors.svg" alt="cut" title="cut" />
                    <div
                        v-if="isSingleSided"
                        class="flashcard"
                        :class="'fc-row-' + Math.floor(page.filter((item) => item !== false).length)"
                        :style="{ borderColor: style.color }"
                    >
                        <template v-for="(item, itemIndex) in page.filter((item) => item !== false)">
                            <span
                                :id="`flashcard-front-${item.index}`"
                                :key="'card-a-' + itemIndex"
                                class="flashcard px-2 text-pre"
                                :style="{ borderColor: style.color, height: flashcardSize + 'in' }"
                                @click="getDefinitionClick('term', item.index)"
                            >
                                <FlashcardItemPreview
                                    v-if="item"
                                    type="term"
                                    :item="item"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>

                            <span
                                :id="`flashcard-back-${item.index}`"
                                :key="'card-b-' + itemIndex"
                                class="flashcard px-2 text-pre"
                                :style="{ borderColor: style.color, height: flashcardSize + 'in' }"
                                @click="getDefinitionClick('definition', item.index)"
                            >
                                <FlashcardItemPreview
                                    v-if="item"
                                    type="definition"
                                    :item="item"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>
                        </template>
                    </div>
                    <div
                        v-else
                        class="flashcard"
                        :class="'fc-row-' + Math.ceil(page.filter((item) => item !== false).length / 2) + 1"
                        :style="{ borderColor: style.color }"
                    >
                        <template v-for="(item, itemIndex) in Math.ceil(page.filter((item) => item !== false).length / 2)">
                            <span
                                :id="`flashcard-${pageIndex % 2 === 0 ? 'front' : 'back'}-${page[itemIndex * 2]?.index}`"
                                :key="'card-a-' + itemIndex"
                                class="flashcard-double-side text-pre"
                                :style="{ borderColor: style.color, height: flashcardSize + 'in' }"
                                @click="
                                    getDefinitionClick(
                                        pageIndex % 2 === 0 ? 'term' : 'definition',
                                        page[itemIndex * 2]?.index,
                                    )
                                "
                            >
                                <FlashcardItemPreview
                                    v-if="page[itemIndex * 2]"
                                    :type="pageIndex % 2 === 0 ? 'term' : 'definition'"
                                    :item="page[itemIndex * 2]"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>

                            <span
                                :id="`flashcard-${pageIndex % 2 === 0 ? 'front' : 'back'}-${page[itemIndex * 2 + 1]?.index}`"
                                :key="'card-b-' + itemIndex"
                                class="flashcard-double-side text-pre"
                                :style="{ borderColor: style.color, height: flashcardSize + 'in' }"
                                @click="
                                    getDefinitionClick(
                                        pageIndex % 2 === 0 ? 'term' : 'definition',
                                        page[itemIndex * 2 + 1]?.index,
                                    )
                                "
                            >
                                <FlashcardItemPreview
                                    v-if="page[itemIndex * 2 + 1]"
                                    :type="pageIndex % 2 === 0 ? 'term' : 'definition'"
                                    :item="page[itemIndex * 2 + 1]"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>
                        </template>
                    </div>
                </div>
            </Document>
            <DocumentVertical
                v-if="flashcard.page_setup.flashcard_type === '3x5'"
                :key="'page-' + pageIndex"
                class="render-page"
                :index="pageIndex"
                :header-height="style.threeByFive.firstPageHeaderHeightInches"
                :cards-count="
                    flashcard.page_setup.flashcard_print_type === 'single-side' || !flashcard.flashcard_print_type
                        ? Math.ceil(page.filter((item) => item).length / 2)
                        : Math.ceil(page.filter((item) => item).length / 2)
                "
                :document-first-page-height="flashcardFirstPageHeight"
            >
                <div class="flashcard-vertical-container body-content" :style="{ 'padding-top': getTopPadding(pageIndex) }">
                    <img class="scissors" src="../../../img/scissors.svg" alt="cut" title="cut" />
                    <div
                        v-if="isSingleSided"
                        class="flashcard"
                        :class="
                            'fc-row-' +
                            (flashcard.flashcard_item_height > page.filter((item) => item !== false).length
                                ? page.filter((item) => item !== false).length
                                : flashcard.flashcard_item_height)
                        "
                        :style="{ borderColor: style.color }"
                    >
                        <template v-for="(item, itemIndex) in 2">
                            <span
                                :id="`flashcard-front-${page[itemIndex].index}`"
                                :key="'card-a-' + itemIndex"
                                class="flashcard px-3 text-pre"
                                :style="{ borderColor: style.color, width: '5in', height: '3in' }"
                                @click="getDefinitionClick('term', page[itemIndex].index)"
                            >
                                <FlashcardItemPreview
                                    v-if="page[itemIndex]"
                                    type="term"
                                    :item="page[itemIndex]"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>

                            <span
                                :id="`flashcard-back-${page[itemIndex].index}`"
                                :key="'card-b-' + itemIndex"
                                class="flashcard px-3 text-pre"
                                :style="{ borderColor: style.color, width: '5in', height: '3in' }"
                                @click="getDefinitionClick('definition', page[itemIndex].index)"
                            >
                                <FlashcardItemPreview
                                    v-if="page[itemIndex]"
                                    type="definition"
                                    :item="page[itemIndex]"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>
                        </template>
                    </div>
                    <div
                        v-else
                        class="flashcard text-pre"
                        :class="
                            'fc-row-' +
                            (flashcard.flashcard_item_height > Math.ceil(page.filter((item) => item).length / 2)
                                ? Math.ceil(page.filter((item) => item).length / 2)
                                : flashcard.flashcard_item_height)
                        "
                        :style="{ borderColor: style.color }"
                    >
                        <template v-for="(item, itemIndex) in 2">
                            <span
                                :id="`flashcard-${pageIndex % 2 === 0 ? 'front' : 'back'}-${page[itemIndex * 2]?.index}`"
                                :key="'card-a-' + itemIndex"
                                class="flashcard-double-side text-pre"
                                :style="{ borderColor: style.color, width: '5in', height: '3in' }"
                                @click="
                                    getDefinitionClick(
                                        pageIndex % 2 === 0 ? 'term' : 'definition',
                                        page[itemIndex * 2]?.index,
                                    )
                                "
                            >
                                <FlashcardItemPreview
                                    v-if="page[itemIndex * 2]"
                                    :type="pageIndex % 2 === 0 ? 'term' : 'definition'"
                                    :item="page[itemIndex * 2]"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>

                            <span
                                :id="`flashcard-${pageIndex % 2 === 0 ? 'front' : 'back'}-${page[itemIndex * 2 + 1]?.index}`"
                                :key="'card-b-' + itemIndex"
                                class="flashcard-double-side text-pre"
                                :style="{ borderColor: style.color, width: '5in', height: '3in' }"
                                @click="
                                    getDefinitionClick(
                                        pageIndex % 2 === 0 ? 'term' : 'definition',
                                        page[itemIndex * 2 + 1]?.index,
                                    )
                                "
                            >
                                <FlashcardItemPreview
                                    v-if="page[itemIndex * 2 + 1]"
                                    :type="pageIndex % 2 === 0 ? 'term' : 'definition'"
                                    :item="page[itemIndex * 2 + 1]"
                                    :page-index="pageIndex"
                                ></FlashcardItemPreview>
                            </span>
                        </template>
                    </div>
                </div>
            </DocumentVertical>
        </template>
    </div>
</template>

<script>
import Wordbank from '../../libraries/wordbank'
import { mapGetters, mapState } from 'vuex'
import Document from '../document.vue'
import DocumentVertical from '../document-vertical.vue'
import FlashcardItemPreview from '../../components/FlashcardItemPreview.vue'

export default {
    name: 'Flashcard',
    components: {
        Document,
        DocumentVertical,
        FlashcardItemPreview,
    },
    props: ['preview'],
    data() {
        return {
            documents: [],
            backSidedDocuments: [],
            isSingleSided: true,
            instructionHeight: 0,
            documentFetchTimeout: null,
            setDocumentTimeout: null,
        }
    },
    computed: {
        ...mapGetters({
            instructions: 'document/documentInstructions',
            flashcard: 'document/flashcard',
            style: 'document/documentStyle',
            omni_student: 'document/omniStudent',
            flashcardHeaderHeight: 'document/flashcardHeaderHeight',
            flashcardHeightEmptyPage: 'document/flashcardHeightEmptyPage',
        }),
        ...mapState(['document']),
        flashcardSize() {
            return this.flashcard.flashcard_item_height
        },
        title_font_size() {
            return this.$store.state.document.title_font_size
        },
        omniStudent: {
            get() {
                return this.omni_student
            },
            set(value) {
                this.$store.dispatch('document/setOmniStudent', parseInt(value))
            },
        },
        showStudentInfo() {
            return !this.omniStudent && this.document.student_info_visible
        },
        getDocumentHeaderHeight() {
            return this.index === 1 && this.flashcard.page_setup.flashcard_print_type === 'double-side'
                ? `${this.style.threeByFive.firstPageHeaderHeightInches}in`
                : 'auto'
        },
        page_count() {
            return this.backSidedDocuments.length > this.documents.length
                ? this.backSidedDocuments.length
                : this.documents.length
        },
        flashcardFirstPageHeight: {
            get() {
                return this.flashcardHeaderHeight
            },
            set(value) {
                this.$store.dispatch('document/setFlashcardHeaderHeight', value)
            },
        },
        header() {
            return document.getElementsByClassName('headers')[0]
        },
    },
    watch: {
        'flashcard.content.items': {
            handler: function () {
                this.renderFlashcards()
                this.resizeDocument()
            },
            deep: true,
            immediate: true,
        },
        'flashcard.page_setup.flashcard_print_type': {
            immediate: true,
            handler(val) {
                this.isSingleSided = val === 'single-side'
                this.renderFlashcards()
            },
        },
        title_font_size: {
            immediate: true,
            handler() {
                this.renderFlashcards()
            },
        },
        'flashcard.page_setup.flashcard_type': {
            immediate: true,
            handler() {
                this.renderFlashcards()
                this.setPageOrientationStyle()
            },
        },
        flashcardHeaderHeight: {
            immediate: true,
            handler() {
                this.renderFlashcards()
            },
        },
        'flashcard.flashcard_item_height': {
            immediate: true,
            handler() {
                this.renderFlashcards()
            },
        },
        omni_student: {
            immediate: true,
            handler() {
                this.renderFlashcards()
            },
        },
        page_count: {
            handler(newValue, oldValue) {
                if (newValue != oldValue) this.$store.dispatch('document/setPageCount', newValue)
            },
            immediate: true,
        },
        header: {
            immediate: true,
            handler() {
                this.renderFlashcards()
            },
        },
    },
    mounted() {
        window.addEventListener('load', () => {
            this.setPageOrientationStyle()
        })
        this.renderFlashcards()
    },
    methods: {
        setDocuments() {
            let sum = 0
            let alldocuments
            let itemsCount
            let flashcardsOnEmptyPage = Math.floor(this.flashcardHeightEmptyPage / this.flashcardSize)
            if (this.flashcardHeightEmptyPage - this.flashcardFirstPageHeight < this.flashcardSize) {
                this.$store.dispatch('document/setOmniStudent', 0)
                if (this.flashcard.page_setup.flashcard_print_type === 'single-side') {
                    alldocuments = [[]]
                    itemsCount = 10
                } else {
                    alldocuments = [[], []]
                    itemsCount = 10
                }
            } else {
                alldocuments = []
                itemsCount = Math.floor(
                    (this.$store.state.document.flashcardHeight - this.flashcardFirstPageHeight) / this.flashcardSize,
                )
            }

            let documents = []
            let oldDocuments = []
            let wb = new Wordbank(this.flashcard.shuffle_seed)
            if (!this.omniStudent) {
                alldocuments.push(
                    ...wb.chunk(
                        this.flashcard.content.items,
                        parseInt(
                            this.flashcard.page_setup.flashcard_type === '3x5'
                                ? this.flashcard.page_setup.flashcard_print_type === 'single-side'
                                    ? 2
                                    : 4
                                : this.flashcard.page_setup.flashcard_print_type === 'single-side'
                                  ? itemsCount
                                  : itemsCount * 2,
                        ),
                    ),
                )
                if (alldocuments.length >= 1) {
                    oldDocuments = alldocuments.slice(0, 1)
                    oldDocuments.forEach((item) => {
                        sum += item.length
                    })
                    let newdocuments = wb.chunk(
                        this.flashcard.content.items.slice(sum),
                        parseInt(
                            this.flashcard.page_setup.flashcard_type === '3x5'
                                ? this.flashcard.page_setup.flashcard_print_type === 'single-side'
                                    ? 2
                                    : 4
                                : this.flashcard.page_setup.flashcard_print_type === 'single-side'
                                  ? flashcardsOnEmptyPage
                                  : flashcardsOnEmptyPage * 2,
                        ),
                    )
                    documents = [...oldDocuments, ...newdocuments]
                } else {
                    documents = alldocuments
                }
            } else {
                alldocuments.push(
                    ...wb.chunk(
                        this.flashcard.content.items,
                        parseInt(
                            this.flashcard.page_setup.flashcard_type === '3x5'
                                ? this.flashcard.page_setup.flashcard_print_type === 'single-side'
                                    ? 2
                                    : 4
                                : this.flashcard.page_setup.flashcard_print_type === 'single-side'
                                  ? itemsCount
                                  : itemsCount * 2,
                        ),
                    ),
                )
                documents = alldocuments
            }

            //documents cannot be empty
            if (documents.length == 0) {
                documents.push([])
            }

            //pad the documents if they're not long enough
            if (
                documents[documents.length - 1].length < Math.ceil((10 - this.flashcardFirstPageHeight) / this.flashcardSize)
            ) {
                //compare it to the length of the cards per page

                for (
                    let pl = documents[documents.length - 1].length;
                    pl < Math.ceil((10 - this.flashcardFirstPageHeight) / this.flashcardSize);
                    pl++
                ) {
                    documents[documents.length - 1].push(false)
                }
            }

            this.documents = documents
            var index = 0
            this.documents.forEach((doc) => {
                doc.forEach((item) => {
                    if (item) {
                        item['index'] = index++
                    }
                })
            })
            if (this.flashcard.page_setup.flashcard_print_type === 'double-side') {
                let backSideDocs = []
                for (let i = 0; i < this.documents.length; i++) {
                    let frontDocs = []
                    let backDocs = []
                    for (let j = 0; j < this.documents[i].length + 1; j++) {
                        if (this.documents[i][j]) {
                            frontDocs.push({ ...this.documents[i][j] })
                        } else {
                            frontDocs.push(false)
                        }
                        if (j % 2 === 0) {
                            if (this.documents[i][j + 1]) {
                                backDocs.push({ ...this.documents[i][j + 1] })
                            } else {
                                backDocs.push(false)
                            }
                        } else {
                            if (this.documents[i][j - 1]) {
                                backDocs.push({ ...this.documents[i][j - 1] })
                            } else {
                                backDocs.push(false)
                            }
                        }
                    }
                    backSideDocs.push(frontDocs, backDocs)
                }
                this.backSidedDocuments = backSideDocs
            }
        },
        getFlashcardHeight() {
            return 10 - this.getPageContentHeight()
        },
        getTopPadding(index) {
            if (this.omni_student && this.flashcard.page_setup.flashcard_print_type === 'double-side' && index < 2) {
                return ''
            }
            return !this.omniStudent
                ? index < 2
                    ? `${this.style.threeByFive.instructionPaddingBorderHeight}px`
                    : ''
                : `${this.style.threeByFive.instructionPaddingBorderHeight}px`
        },
        getPageContentHeight() {
            let fontSize = this.$store.state.document.title_font_size
            if (fontSize < 40) {
                return this.document.title_visible === 0 &&
                    this.document.student_info_visible === 0 &&
                    this.instructions.visible === 0
                    ? 0
                    : 2 + this.instructionHeight
            } else if (fontSize >= 40 && fontSize < 52) {
                return this.document.title_visible === 0 &&
                    this.document.student_info_visible === 0 &&
                    this.instructions.visible === 0
                    ? 0
                    : 2 + this.instructionHeight
            } else if (fontSize >= 52 && fontSize < 65) {
                return this.document.title_visible === 0 &&
                    this.document.student_info_visible === 0 &&
                    this.instructions.visible === 0
                    ? 0
                    : 3 + this.instructionHeight
            }
        },
        instructionHeightChanged(value) {
            clearTimeout(this.documentFetchTimeout)
            this.documentFetchTimeout = setTimeout(() => {
                this.instructionHeight = value
                this.renderFlashcards()
            }, 200)
        },
        getDefinitionClick(target, index) {
            this.$store.dispatch('document/setWidgetStatus', {
                wordsDefinition: true,
                focusedItem: { target, index },
            })
        },
        setPageOrientationStyle() {
            let styleTag = document.getElementById('print-orientation')
            var css = '@page { size: portrait; }',
                head = document.head || document.getElementsByTagName('head')[0],
                style = document.createElement('style')
            if (styleTag) {
                styleTag.remove()
            }
            if (this.flashcard.page_setup.flashcard_type === '3x5') {
                css = '@page { size: landscape; }'
            }
            style.id = 'print-orientation'

            style.type = 'text/css'
            style.media = 'print'

            if (style.styleSheet) {
                style.styleSheet.cssText = css
            } else {
                style.appendChild(document.createTextNode(css))
            }

            head.appendChild(style)
        },
        resizeDocument() {
            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        getHeaderHeight() {
            // get the header height on the first page in inches
            const header = document.getElementsByClassName('headers')[0] || null
            const headerHeight = header
                ? header.getBoundingClientRect().height
                : this.flashcard.page_setup.flashcard_type === '3x5'
                  ? this.style.threeByFive.firstPageHeaderHeightInches
                  : this.style.firstPageHeaderHeightInches
            return header ? headerHeight / 96 : headerHeight
        },
        renderFlashcards() {
            this.$nextTick(() => {
                this.flashcardFirstPageHeight = this.getHeaderHeight()
                this.setDocuments()
            })
        },
    },
}
</script>

<style lang="scss">
.flashcard-container {
    flex-grow: 1;
    position: relative;

    .scissors {
        position: absolute;
        height: 25px;
        left: 7.49in;
        top: 0.5in;
    }

    .flashcard {
        display: grid;
        grid-template-columns: 50% 50%;
        border-bottom-width: 2px;
        border-bottom-style: dotted;

        &.fc-row-3 {
            grid-template-rows: repeat(3, 33.3%);
        }

        &.fc-row-4 {
            grid-template-rows: repeat(4, 25%);
        }

        &.fc-row-5 {
            grid-template-rows: repeat(5, 20%);
        }

        &.fc-row-6 {
            grid-template-rows: repeat(6, 16.66%);
        }

        &.fc-row-7 {
            grid-template-rows: repeat(7, 14.28%);
        }

        &.fc-row-8 {
            grid-template-rows: repeat(8, 12.5%);
        }

        .flashcard {
            display: flex;
            justify-content: center;
            align-items: center;
            border-width: 2px;
            border-style: dotted;
            border-bottom: 0;
            // padding: 0.25in;
            flex: 1;
            overflow: hidden;

            &:nth-child(odd) {
                border-right-width: 1px;
                border-right-style: solid;
            }

            &:nth-child(even) {
                border-left-width: 1px;
                border-left-style: solid;
            }
        }

        .flashcard-double-side {
            display: flex;
            justify-content: center;
            align-items: center;
            border-width: 2px;
            border-style: dotted;
            border-bottom: 0;
            // padding: 0.25in;
            flex: 1;
            overflow: hidden;

            &:nth-child(odd) {
                border-right-width: 1px;
                border-right-style: dotted;
            }

            &:nth-child(even) {
                border-left-width: 1px;
                border-left-style: dotted;
            }
        }
    }
}
.flashcard > .attribution:after {
    content: 'Created with My Flashcard Maker' !important;
    background-image: url('/images/flashcards-mark.svg') !important;
}
.flashcard-vertical-container {
    flex-grow: 1;
    position: relative;

    .scissors {
        position: absolute;
        height: 25px;
        left: 9.99in;
        top: 0.5in;
    }

    .flashcard {
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        border-bottom-width: 2px;
        border-bottom-style: dotted;

        &.fc-row-3 {
            grid-template-rows: repeat(3, 33.3%);
        }

        &.fc-row-4 {
            grid-template-rows: repeat(4, 25%);
        }

        &.fc-row-5 {
            grid-template-rows: repeat(5, 20%);
        }

        &.fc-row-6 {
            grid-template-rows: repeat(6, 16.66%);
        }

        &.fc-row-7 {
            grid-template-rows: repeat(7, 14.28%);
        }

        &.fc-row-8 {
            grid-template-rows: repeat(8, 12.5%);
        }

        .flashcard {
            display: flex;
            justify-content: center;
            align-items: center;
            border-width: 2px;
            border-top-style: dotted;
            border-bottom-style: dotted;
            border-bottom: 0;
            // padding: 0.25in;
            flex: 1;
            overflow: hidden;

            &:nth-child(odd) {
                border-right-width: 1px;
                border-right-style: solid;
                border-left-width: 2px;
                border-left-style: dotted;
            }

            &:nth-child(even) {
                border-left-width: 1px;
                border-left-style: solid;
                border-right-width: 2px;
                border-right-style: dotted;
            }
        }

        .flashcard-double-side {
            display: flex;
            justify-content: center;
            align-items: center;
            border-width: 2px;
            border-style: dotted;
            border-bottom: 0;
            // padding: 0.25in;
            flex: 1;
            overflow: hidden;

            &:nth-child(odd) {
                border-right-width: 1px;
                border-right-style: dotted;
            }

            &:nth-child(even) {
                border-left-width: 1px;
                border-left-style: dotted;
            }
        }
    }
}

.vertical-sheet-orientation {
    writing-mode: vertical-rl;
    text-orientation: sideways;
}

.rotate-text {
    transform: rotate(180deg);
}

.flashcard-block {
    height: 100%;
    text-align: center;

    p {
        margin: 0;
    }
}
</style>
