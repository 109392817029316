<template>
    <p class="text-danger overflow-hidden">
        <span v-if="isHtml" class="h-100" v-html="displayAnswer"></span>
        <span v-else>{{ displayAnswer }}</span>
    </p>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        answer: {
            type: String,
            default: '',
        },
        isHtml: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            showAnswerKey: 'document/showAnswerKey',
        }),
        displayAnswer() {
            return this.showAnswerKey ? this.answer : ''
        },
    },
}
</script>
