<template>
    <hr
        v-if="!item.hide"
        class="mx-auto"
        :style="{
            width: item.data.line_width + '%',
            borderTopColor: item.data.line_color,
            borderTopStyle: item.data.line_style,
            borderTopWidth: item.data.line_weight + 'px',
            marginTop: 0,
            marginBottom: 0,
        }"
    />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'DividerPreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
})
</script>
