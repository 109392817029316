import { defineComponent } from 'vue'

const CONSTANTS = {
    DEFAULT_NAV_HEIGHT: 68,
    BREATHING_SPACE: 24,
    MIN_BOTTOM_VIEWPORT_SPACE: 500,
    SCROLL_THRESHOLD: 10,
} as const

interface Position {
    top: number
}

export default defineComponent({
    name: 'WithAutoScroll',
    computed: {
        viewportHeight(): number {
            return window.innerHeight
        },
        stickyHeaderHeight(): number {
            const navBarHeight =
                document.getElementById('primary-navigation')?.getBoundingClientRect()?.height ||
                CONSTANTS.DEFAULT_NAV_HEIGHT
            const zoomContainerHeight =
                document.getElementById('zoomContainer')?.getBoundingClientRect()?.height || CONSTANTS.DEFAULT_NAV_HEIGHT
            return navBarHeight + zoomContainerHeight
        },
        breathingGap(): number {
            return this.stickyHeaderHeight + CONSTANTS.BREATHING_SPACE
        },
    },
    methods: {
        scrollIntoView(ref: string): void {
            const element = document.getElementById(ref) as HTMLElement | null
            if (!element) return

            const elementBoundings = element.getBoundingClientRect()

            if (ref.includes('goto-id')) {
                this.handleSidebarScroll(element, elementBoundings)
            }

            if (this.isInViewport(element, { top: this.breathingGap })) return

            this.handleMainContentScroll(ref, element, elementBoundings)
        },

        handleSidebarScroll(element: HTMLElement, bounds: DOMRect): void {
            const shouldScroll =
                !this.isInViewport(element, { top: this.stickyHeaderHeight }) &&
                (bounds.top < this.stickyHeaderHeight ||
                    this.viewportHeight - bounds.top < CONSTANTS.MIN_BOTTOM_VIEWPORT_SPACE)

            if (shouldScroll) {
                this.scrollToPosition(element, 'center')
            }
        },

        handleMainContentScroll(ref: string, element: HTMLElement, bounds: DOMRect): void {
            let el = element
            const anchorSelector = this.getAnchor(ref)
            const anchor = document.getElementById(anchorSelector)
            const baseSelector = `base-${ref.substr(8)}`
            const base = document.getElementById(baseSelector)

            if (anchor) el = anchor

            if (bounds.top < this.breathingGap + CONSTANTS.SCROLL_THRESHOLD) {
                // Scroll element down
                this.scrollToPosition(el, 'start')
            } else if (this.viewportHeight - bounds.top < this.breathingGap + CONSTANTS.SCROLL_THRESHOLD) {
                // Scroll element up
                this.handleUpwardScroll(el, bounds, base)
            }
        },

        handleUpwardScroll(element: HTMLElement, bounds: DOMRect, base: HTMLElement | null): void {
            const isElementTallerThanViewport =
                bounds.height > this.viewportHeight - (this.breathingGap + CONSTANTS.SCROLL_THRESHOLD)

            if (isElementTallerThanViewport) {
                this.scrollToPosition(element, 'start')
            } else {
                const position = base ? 'end' : 'center'
                this.scrollToPosition(base || element, position)
            }
        },

        scrollToPosition(element: Element, block: ScrollLogicalPosition = 'start'): void {
            element.scrollIntoView({
                behavior: 'smooth',
                block,
            })
        },

        isInViewport(element: HTMLElement, offset: Position = { top: 0 }) {
            const rect = element.getBoundingClientRect()
            return (
                rect.top >= offset.top &&
                rect.left >= 0 &&
                rect.bottom <= (this.viewportHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },
        getAnchor(ref: string): string {
            if (ref === 'docHeader') {
                return 'anchor-docHeader'
            }

            if (ref === 'instructions' || ref === 'bingo_words') {
                return `anchor-${ref}-0`
            }

            if (ref === 'bingo_call_list') {
                return `anchor-${ref}`
            }

            return `anchor-${ref.substr(8)}`
        },
    },
})
