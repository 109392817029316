<template>
    <div
        v-if="!item.hide"
        class="section-header-title mb-0"
        :style="{
            fontSize: item.data.font_size + 'px',
            color: item.data.color,
            textAlign: item.data.align,
            lineHeight: style.font_space + 'em',
            minHeight: style.font_space + 'em',
        }"
        :class="$fonts.getFontClass(item.data.font)"
        v-html="item.data.title"
    ></div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'SectionHeaderPreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            style: 'document/documentStyle',
        }),
    },
})
</script>
