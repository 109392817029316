import { mapGetters } from 'vuex'
import { defaultInlineImageProperties } from '../store/helpers/documentHelpers'

export default {
    computed: {
        ...mapGetters({
            entityType: 'document/entityType',
            isWorksheet: 'document/isWorksheet',
        }),
        inlineImages() {
            return this.$store.getters[`document/${this.entityType}InlineImages`]
        },
        showInlineImagesManager() {
            return this.inlineImages.length > 0
        },
        btnImageLink() {
            if (this.isSvg) {
                return this.svgLink
            } else {
                return this.url
            }
        },
        svgLink() {
            return `data:image/svg+xml,${encodeURIComponent(this.svg)}`
        },
        isQueueWorker() {
            return window.isQueueWorker
        },
        inlineImageControlStyle() {
            const scaleX = this.properties.flipHorizontal ? -1 : 1
            const scaleY = this.properties.flipVertical ? -1 : 1
            const rotate = `rotate(${this.properties.rotation}deg)`

            return [{ transform: `scaleX(${scaleX}) scaleY(${scaleY}) ${rotate}` }]
        },
    },
    methods: {
        inlineImagesProperties(imageId, currentSize = 0) {
            const size = currentSize ? parseInt(currentSize) : 1

            if (!imageId) {
                return {
                    width: size,
                    height: size,
                    lockAspectRatio: 1,
                }
            }

            const image = this.getInlineImage(imageId)

            return {
                ...(image ?? { lockAspectRatio: 1 }),
                ...{ width: size, height: size },
            }
        },
        getInlineImage(imageId) {
            return (
                this.inlineImages?.find((image) => {
                    return image.id === imageId
                }) ?? { ...defaultInlineImageProperties }
            )
        },
        hideBorder(item = {}) {
            return (
                (item.term_image?.length && item.definition_image?.length) ||
                (!item.term_image?.length && !item.definition_image?.length)
            )
        },
        async loadImage() {
            if (!this.properties?.objectId) return

            this.rotation = this.properties.rotation || 0
            this.flipImageHorizontally = this.properties.flipHorizontal || false
            this.flipImageVertically = this.properties.flipVertical || false

            if (this.shouldLoadSvg) {
                await this.loadSvg({ svgId: this.properties.objectId, isInlineImage: true })
            }
        },
        async deleteInlineImage() {
            try {
                await this.$store.dispatch('document/removeInlineImage', {
                    id: this.properties.id,
                    objectId: this.properties.objectId,
                })
            } catch (error) {
                throw error
            }
        },
    },
}
