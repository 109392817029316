<template>
    <div
        v-if="!item.hide"
        class="document-page-break page-breaker w-100 text-center text-bold pt-2"
        style="line-height: 0px; font-size: 14.6665px"
    >
        PAGE BREAK
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'PageBreakPreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';
.page-breaker {
    border-top: 3px dashed $gray-400;
    color: $gray-400;
}
.hide {
    opacity: 0;
}
</style>
