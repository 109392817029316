<template>
    <VueDraggableResizable
        ref="sticker-image"
        class="position-absolute"
        :class="stickerImageClasses"
        :style="{ cursor: !disabled ? 'move' : 'default' }"
        :draggable="!disabled"
        :resizable="!disabled"
        :x="properties.x"
        :y="properties.y"
        :w="properties.width"
        :h="properties.height"
        :scale="zoomRatio"
        :lock-aspect-ratio="properties.lockAspectRatio"
        :min-width="50"
        :min-height="50"
        :parent="true"
        :on-resize="onResizeCallback"
        :on-drag-start="() => setDragging(true)"
        :on-drag="moveTo"
        :handles="hideMiddleHandlers ? ['tl', 'tr', 'br', 'bl'] : ['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml']"
        :active="isActive"
        tabindex="0"
        @dragstop="saveDocument"
        @resizestop="saveDocument"
        @activated="activate(true)"
        @deactivated="activate(false)"
    >
        <ImageControlBar
            v-if="isActive"
            class="z-10"
            :color="color"
            :is-svg="isSvg"
            :lock-aspect-ratio="properties.lockAspectRatio"
            :hide-aspect-ratio="hideMiddleHandlers"
            :class="{ 'hide-aspect-ratio': hideMiddleHandlers }"
            @updateColor="updateColor"
            @saveColor="saveColor"
            @duplicate="duplicateIcon"
            @rotate="rotateIcon"
            @flip-horizontal="flipHorizontal"
            @flip-vertical="flipVertical"
            @bring-front="updateZIndex('front')"
            @send-back="updateZIndex('back')"
            @delete="deleteIcon"
            @lockAspectRatio="toggleAspectRatio"
        />
        <div :id="properties.id" :style="imageStyles">
            <span :id="`image-anchor-${properties.id}`" class="position-absolute" style="top: -50px"></span>
            <ImageSpinner v-if="imageIsLoading" class="w-100 loading" show-pre-loader />
            <div
                v-else-if="(imageId && imageId.endsWith('.svg')) || (url && url.startsWith('<svg'))"
                ref="myImage"
                v-html="svg"
            ></div>
            <div v-else-if="imageId.split('.').length > 1" class="vue-load-image">
                <img ref="myImage" key="image" :src="url" class="w-100 sticker-image" alt="my uploaded image" />
            </div>
        </div>
    </VueDraggableResizable>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ImageUploadApi from '../../apis/ImageUploadApi'
import uuidv1 from 'uuid/v1'
import Images from '../../mixins/Images'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
    components: {
        ImageControlBar: () => import('./ImageControlBar.vue'),
        ImageSpinner: () => import('./ImageSpinner.vue'),
        VueDraggableResizable: () => import('vue-draggable-resizable'),
    },

    mixins: [Images],
    props: {
        properties: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Number,
            default: 0,
        },
        imageId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            activated: false,
            dragging: false,
            document: null,
            step: 1,
            accel: 1.3,
            loading: false,
            imageColor: '',
            listenersLoaded: false,
        }
    },

    computed: {
        ...mapState(['user']),
        ...mapGetters({
            entityType: 'document/entityType',
            persistAction: 'document/immediatePersistAction',
            isLoggedIn: 'user/isLoggedIn',
            zoomRatio: 'document/getScaleRatio',
            documentWidth: 'document/documentWidth',
            documentModel: 'document/documentModel',
            loadingImage: 'document/loadingImage',
        }),
        hideMiddleHandlers() {
            return this.activated && this.properties.rotation && ![0, 360].includes(parseInt(this.properties.rotation))
        },
        originalPageSize() {
            return {
                width: this.document.getBoundingClientRect().width / this.zoomRatio,
                height: this.document.getBoundingClientRect().height / this.zoomRatio,
            }
        },
        stickerImageClasses() {
            return { disabled: this.disabled, 'resizable-image': this.hasImage }
        },
        imageIsLoading() {
            return !this.hasImage && (this.loadingImage || this.loading)
        },
        isActive() {
            return this.activated && !this.imageIsLoading
        },
    },

    watch: {
        async imageId(val) {
            if (val) {
                await this.loadImage()
                this.activated = true
            }
        },
    },

    async mounted() {
        await this.loadImage()
        this.addListeners()
    },

    methods: {
        async loadImage() {
            if ((!this.imageId && !this.properties.id_icon) || !this.properties.objectId) return

            try {
                this.loading = true
                this.rotation = this.properties.rotation || 0
                this.flipImageHorizontally = this.properties.flipHorizontal || false
                this.flipImageVertically = this.properties.flipVertical || false

                if (this.isSvg) {
                    await this.loadSvg({ svgId: this.imageId })
                }
            } catch (error) {
                throw error
            } finally {
                this.loading = false
            }
        },
        activate(isActive) {
            if (!this.listenersLoaded) this.addListeners()

            if (this.documentModel.is_published) return

            if (!this.disabled) this.activated = isActive
        },
        async deleteIcon() {
            try {
                await this.$store.dispatch('document/removeImage', { id: this.properties.objectId, entity: this.entityType })
                await this.saveDocument()

                await ImageUploadApi.delete(this.properties.objectId)
            } catch (error) {
                throw error
            }
        },
        async moveTo(x, y) {
            try {
                x = Math.min(Math.max(0, x), this.originalPageSize.width - this.properties.width)
                y = Math.min(Math.max(0, y), this.originalPageSize.height - this.properties.height)

                await this.$store.dispatch('document/updateImage', {
                    entity: this.entityType,
                    id: this.properties.id,
                    x,
                    y,
                })
                if (y < 1) {
                    this.$emit('movePage', this.properties, 0)
                } else if (y > this.originalPageSize.height - this.properties.height - 1) {
                    this.$emit('movePage', this.properties, 1)
                }
            } catch (error) {
                throw error
            }
        },
        setDragging(dragging) {
            const pages = document.getElementsByClassName('render-page')
            this.document = pages[this.properties.docIndex]
            this.dragging = dragging
        },
        async onResizeCallback(handle, x, y, width, height) {
            try {
                const update = {
                    entity: this.entityType,
                    id: this.properties.id,
                    x,
                    y,
                    width,
                    height,
                    lockAspectRatio: this.properties.lockAspectRatio,
                    is_icon: this.properties.is_icon,
                }

                if (!this.properties.lockAspectRatio) update.ratioChanged = true

                // TODO: Stop giving different object types to adjustSvgDimensions()
                if (this.isSvg) this.adjustSvgDimensions(update)
                await this.$store.dispatch('document/updateImage', update)
            } catch (error) {
                throw error
            }
        },

        async saveDocument() {
            try {
                if (this.isLoggedIn) await this.$store.dispatch(this.persistAction)
            } catch (error) {
                throw error
            }
        },

        async duplicateIcon() {
            try {
                let newFile = { ...this.properties, ...this.svgToFile(this.$refs.myImage, this.properties.objectId) }
                newFile.id = uuidv1()
                newFile.entity = this.entityType

                const { id } = await ImageUploadApi.duplicateFile(this.properties.objectId)

                newFile.objectId = id
                await this.addNewAttachmentToDocument([newFile])
            } catch (error) {
                throw error
            }
        },

        async addNewAttachmentToDocument(attachment) {
            try {
                if (!attachment || attachment.length === 0) return

                if (!attachment[0].height) attachment[0].height = attachment[0].width * attachment[0].ratio
                attachment[0].y = 300
                attachment[0].x = 300

                await this.$store.dispatch('document/setIsInlineImage', false)
                await this.$store.dispatch('document/setImage', attachment)
            } catch (error) {
                throw error
            }
        },

        svgToFile(svgElement, fileName) {
            if (!svgElement || !fileName) return

            try {
                const serializer = new XMLSerializer()
                const svgString = serializer.serializeToString(svgElement)

                const blob = new Blob([svgString], { type: 'image/svg+xml' })
                return new File([blob], fileName, { type: 'image/svg+xml', lastModified: new Date() })
            } catch (error) {
                throw error
            }
        },

        async updateZIndex(move) {
            try {
                await this.$store.dispatch('document/updateImageZIndex', {
                    id: this.properties.id,
                    move: move,
                    entity: this.entityType,
                })
                await this.saveDocument()
            } catch (error) {
                throw error
            }
        },

        async toggleAspectRatio() {
            try {
                await this.$store.dispatch('document/updateImage', {
                    entity: this.entityType,
                    id: this.properties.id,
                    lockAspectRatio: !this.properties.lockAspectRatio,
                    ratio: false,
                })

                await this.saveDocument()
            } catch (error) {
                throw error
            }
        },

        addListeners() {
            this.$nextTick(() => {
                const resizableImageEle = this.$refs['sticker-image']?.$el
                if (resizableImageEle) {
                    this.listenersLoaded = true
                    resizableImageEle.addEventListener('keydown', async (e) => {
                        e.preventDefault()

                        if (!this.activated) return

                        if (e.key === 'Delete' || e.key === 'Backspace') {
                            await this.deleteIcon()
                        } else if (e.key === 'ArrowLeft') {
                            this.step *= this.accel
                            await this.moveTo(this.properties.x - this.step, this.properties.y)
                        } else if (e.key === 'ArrowRight') {
                            this.step *= this.accel
                            await this.moveTo(this.properties.x + this.step, this.properties.y)
                        } else if (e.key === 'ArrowUp') {
                            this.step *= this.accel
                            await this.moveTo(this.properties.x, this.properties.y - this.step)
                        } else if (e.key === 'ArrowDown') {
                            this.step *= this.accel
                            await this.moveTo(this.properties.x, this.properties.y + this.step)
                        }
                    })
                    resizableImageEle.addEventListener('keyup', (e) => {
                        e.preventDefault()

                        if (!this.activated) return

                        this.step = 1
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.resizable-image {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    image-rendering: auto;
    box-sizing: border-box;
    top: 0;
    border: none;

    &:hover {
        outline: 1px solid #294fd6;
    }

    &.active {
        outline: 0.75px solid #294fd6;
    }

    &:focus-visible {
        outline: none;
    }

    &.disabled {
        outline: none;
    }

    &.disabled:hover {
        outline: none;
    }

    .vue-load-image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;

            &.preloader {
                padding: 10%;
                outline: 1px solid #8080f1;
                opacity: 0.6;
            }
        }

        .preloader-spinner {
            position: absolute;
            top: 3px;
            right: 3px;
            border-width: 1px;
            border-radius: 50%;
        }
    }
}

.z-10 {
    z-index: 10;
}

.resource-box {
    position: fixed;
    bottom: -60px;
    height: auto;
    max-height: 40px;
}
</style>

<style lang="scss">
.vdr {
    border: none;
}
</style>
