import { mapGetters } from 'vuex'
import { primaryConfig } from '../objects/FittyConfigs'
import { debounce } from 'lodash'

export default {
    computed: {
        ...mapGetters({
            isBingo: 'document/isBingo',
        }),
    },
    methods: {
        adjustContainerSize(contentElement, maxSize) {
            if (contentElement) {
                contentElement.style.fontSize = `${maxSize}px`
                contentElement.style.wordBreak = 'initial'
            }

            let minFontSize = primaryConfig.minSize
            let breakWord = false
            let maxFontSize = maxSize ?? primaryConfig.maxSize

            while (minFontSize < maxFontSize - 1) {
                const fontSize = Math.floor((minFontSize + maxFontSize) / 2)

                if (contentElement) {
                    contentElement.style.fontSize = `${fontSize}px`
                }

                if (
                    contentElement?.scrollHeight > contentElement?.offsetHeight ||
                    contentElement?.scrollWidth > contentElement?.offsetWidth
                ) {
                    maxFontSize = fontSize
                } else {
                    minFontSize = fontSize
                }
            }

            if (contentElement) {
                contentElement.style.fontSize = `${minFontSize}px`
            }

            if (minFontSize == primaryConfig.minSize) {
                if (contentElement) {
                    contentElement.style.wordBreak = 'break-all'
                }

                breakWord = true
            }

            return { minFontSize, breakWord }
        },
        resizeItems: debounce(function () {
            let contentElements = []
            if (this.isBingo) {
                contentElements = this.$refs.content
            } else {
                contentElements = Array.from(document.getElementsByClassName('content'))
            }

            if (!contentElements || !contentElements.length) return

            contentElements.forEach((item) => {
                this.resizeItem(item)
            })
        }, 150),
        resizeItem(item) {
            const { minFontSize, breakWord } = this.adjustContainerSize(item, this.style.font_size)

            if (this.isBingo) {
                const index = item.getAttribute('data-index')
                if (index >= 0) {
                    const contentElement = document.getElementsByClassName('fit-box-' + index)
                    this.adjustRelatedBoxes(minFontSize, breakWord, contentElement)
                }
            } else {
                this.adjustRelatedBoxes(minFontSize, breakWord, [item])
            }
        },
        adjustRelatedBoxes(fontSize, breakWord, contentElement) {
            for (let i = 0; i < contentElement.length; i++) {
                let element = contentElement[i]
                element.style.fontSize = `${fontSize}px`

                if (breakWord) {
                    element.style.wordBreak = 'break-all'
                } else {
                    element.style.wordBreak = 'initial'
                }
            }
        },
    },
}
