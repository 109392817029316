<template>
    <div ref="content" class="content fit-box" v-html="text"></div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Common from '../mixins/Common'
import FittyTextMixin from '../mixins/FittyText'

export default defineComponent({
    mixins: [Common, FittyTextMixin],
    props: {
        text: {
            type: String,
            required: true,
        },
        maxSize: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters({
            flashcard: 'document/flashcard',
            style: 'document/documentStyle',
        }),
    },
    watch: {
        text: {
            handler() {
                this.adjustText()
            },
            immediate: true,
        },
        'flashcard.content.style': {
            handler() {
                this.adjustText()
            },
            deep: true,
            immediate: true,
        },
        'flashcard.page_setup': {
            handler() {
                this.adjustText()
            },
            deep: true,
            immediate: true,
        },
        'flashcard.flashcard_item_height': {
            handler() {
                this.adjustText()
            },
            immediate: true,
        },
    },
    updated() {
        this.$nextTick(() => {
            this.adjustText()
        })
    },
    methods: {
        adjustText() {
            this.$nextTick(() => {
                this.resizeItem(this.$refs.content)
            })
        },
    },
})
</script>

<style lang="scss" scoped>
.content {
    max-width: 100%;
    max-height: 100%;
    min-height: 15px;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
