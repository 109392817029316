<template>
    <div
        v-if="!item.hide"
        class="d-block snap-element instruction-box mb-0"
        :style="{
            borderWidth: item.style.border_width + 'px',
            borderStyle: item.style.border_style,
            borderColor: item.style.border_color,
            minHeight: style.font_space + 'em', //lineheight
            borderRadius: '3px',
            boxSizing: 'content-box',
            paddingLeft: `${sidePadding}px`,
            paddingRight: `${sidePadding}px`,
            paddingTop: '8px',
            paddingBottom: `${bottomPadding}px`,
        }"
        v-html="item.data.instruction"
    ></div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'InstructionPreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            style: 'document/documentStyle',
        }),
        sidePadding() {
            return 8 + (this.style.font_size * (this.style.font_space - 1)) / 2 + this.style.font_size * 0.1
        },
        bottomPadding() {
            return 8 + this.style.font_size * 0.1
        },
    },
})
</script>
