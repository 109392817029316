export const primaryConfig = {
    minSize: 11,
    maxSize: 64,
    multiLine: true,
}

export const defaultContainerSyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
