export default class Wordbank {
    constructor(shuffle_seed) {
        this.shuffle_seed = shuffle_seed
    }
    chunk(array, size) {
        if (!size || size < 1) size = 1
        const chunked_arr = []
        let index = 0
        while (index < array.length) {
            chunked_arr.push(array.slice(index, size + index))
            index += size
        }
        return chunked_arr
    }

    sortWords(items, sortType) {
        let sortable = []

        for (let i in items) {
            sortable.push(items[i].term)
        }
        if (sortable.length > 0) {
            let chunkSize = Math.floor(sortable.length / 4)
            let remainder = sortable.length % 4

            //sort alphabetically
            if (sortType == 'alpha') {
                sortable.sort(function (a, b) {
                    var textA = a.toUpperCase()
                    var textB = b.toUpperCase()
                    return textA < textB ? -1 : textA > textB ? 1 : 0
                })
                // sort by random shuffle seed
            } else if (sortType == 'rand') {
                let c = new Chance(this.shuffle_seed)
                sortable = c.shuffle(sortable)
            }

            let sorted = [[], [], [], []]

            if (remainder == 0) {
                //should return 4 equal columns
                return this.chunk(sortable, chunkSize)
            } else {
                for (let col = 0; col < 4; col++) {
                    let remainders = 0
                    if (remainder > 0) {
                        remainders = 1
                        remainder--
                    }

                    for (let i = 0; i < chunkSize + remainders; i++) {
                        sorted[col].push(sortable.shift())
                        if (sortable.length == 0) {
                            break
                        }
                    }
                }
            }
            return sorted
        } else {
            return []
        }
    }
}
