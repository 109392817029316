<template>
    <div
        class="inline-image top-padding"
        :class="{ 'd-flex align-items-center justify-content-center': !hasImage }"
        tabindex="0"
        :style="[
            ...inlineImageControlStyle,
            {
                minWidth: `${minSize}px`,
                minHeight: `${minSize}px`,
                height: `${calculatedSize}px`,
                width: `${calculatedSize}px`,
                aspectRatio: 1,
            },
        ]"
    >
        <template v-if="hasImage">
            <svg
                v-if="isSvg && isQueueWorker"
                :key="imageKey"
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid meet"
            >
                <rect width="100%" height="100%" :fill="color" :mask="'url(#preload-mask-' + imageId + ')'" />
            </svg>
            <div v-else :key="imageKey" :style="inlineImageStyle"></div>
        </template>
        <b-spinner v-else-if="!hasImage" variant="primary" aria-hidden="true" class="w-50 h-50"></b-spinner>
    </div>
</template>

<script>
import ImagesMixin from '../../mixins/Images'
import InlineImagesMixin from '../../mixins/InlineImages'

export default {
    mixins: [ImagesMixin, InlineImagesMixin],
    props: {
        imageId: {
            type: String,
            required: false,
            default: '',
        },
        size: {
            type: [String, Number],
            required: false,
            default: 1,
        },
        maxSize: {
            type: Number,
            required: false,
            default: 0,
        },
        minSize: {
            type: Number,
            default: 48,
        },
    },
    computed: {
        calculatedSize() {
            return this.maxSize > 0 && this.properties.width > this.maxSize ? this.maxSize : this.properties.width
        },
        properties() {
            return this.inlineImagesProperties(this.imageId, parseInt(this.size))
        },
        shouldLoadSvg() {
            return this.isSvg
        },
        imageKey() {
            return this.isSvg && this.isWorksheet && !this.isQueueWorker ? this.imageId : this.url
        },
        inlineImageStyle() {
            let background = {}

            if (this.isSvg) {
                background = {
                    backgroundColor: `${this.color}`,
                    maskImage: `url(${this.imageLink})`,
                    maskPosition: `center`,
                    maskSize: `contain`,
                    maskRepeat: `no-repeat`,
                    '-webkit-mask-image': `url(${this.imageLink})`,
                    '-webkit-mask-position': `center`,
                    '-webkit-mask-size': `contain`,
                    '-webkit-mask-repeat': `no-repeat`,
                }
            } else {
                background = {
                    backgroundImage: `url(${this.imageLink})`,
                }
            }

            return {
                ...background,
                width: '100%',
                height: '100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
            }
        },
    },
    watch: {
        'properties.objectId': {
            async handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    await this.loadImage()
                    this.$emit('change')
                }
            },
        },
        'properties.color': {
            handler() {
                if (this.isSvg) this.updateColor()
            },
        },
    },
    async mounted() {
        this.is_inline_image = true
        if (!this.isQueueWorker) {
            await this.loadImage()
        }
    },
}
</script>

<style lang="scss" scoped>
.inline-image {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
    box-sizing: border-box;
    border: none;
    position: relative;
    max-width: -moz-available;
    max-width: -webkit-fill-available;
    max-width: fill-available;
    max-height: -moz-available;
    max-height: -webkit-fill-available;
    max-height: fill-available;
}
.spinner-border {
    border-width: 3px !important;
}
</style>
