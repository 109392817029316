<template>
    <div v-if="!item.hide" class="mb-0">
        <div
            class="word-bank px-4 py-2"
            :style="{
                borderWidth: item.data.border_width + 'px',
                borderStyle: item.data.border_style,
                borderColor: item.data.border_color,
                minHeight: style.font_space + 'em',
                boxSizing: 'content-box',
            }"
        >
            <div class="grid-4">
                <span
                    v-for="(wordItem, index) in item.data.words"
                    :id="`word-${index}`"
                    :key="`word-${index}`"
                    :style="{ textTransform: item.data.text_case }"
                    v-text="wordItem.word"
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'WordBankPreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            style: 'document/documentStyle',
        }),
    },
})
</script>
