import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            rotation: 0,
            flipImageHorizontally: false,
            flipImageVertically: false,
            is_inline_image: false,
        }
    },
    computed: {
        ...mapGetters({
            entityType: 'document/entityType',
            svgImages: 'iconLibrary/svgImages',
        }),
        images() {
            return this.$store.getters[`document/${this.entityType}Images`]
        },
        isSvg() {
            const checkExtension = `${this.properties?.objectId}`?.split('.')

            if (!checkExtension) return false

            return ['svg', 'SVG'].includes(checkExtension[checkExtension.length - 1])
        },
        imageStyles() {
            const scaleX = this.properties.flipHorizontal ? -1 : 1
            const scaleY = this.properties.flipVertical ? -1 : 1
            const rotate = `rotate(${this.properties.rotation}deg)`

            return [
                { transform: `scaleX(${scaleX}) scaleY(${scaleY}) ${rotate}` },
                { height: `${this.properties.height}px` },
                { width: `${this.properties.width}px` },
            ]
        },
        color: {
            async set(value) {
                await this.$store.dispatch('document/updateImage', {
                    entity: this.entityType,
                    id: this.properties.id,
                    color: value,
                    is_inline_image: this.is_inline_image,
                })
            },
            get() {
                return this.properties?.color
            },
        },
        url() {
            if (!this.hasValidObjectId) return null

            if (this.isSvg) {
                return `/images-svg/${this.properties.objectId}`
            } else {
                return `/image/${this.properties.objectId}`
            }
        },
        svg() {
            return this.svgImages.find((i) => i.id === this.properties.objectId)?.svgData
        },
        hasValidObjectId() {
            if (!this.properties?.objectId) return false
            if (typeof this.properties.objectId !== 'string' || this.properties.objectId.split('.').length < 2) return false

            return true
        },
        imageLink() {
            return this.url
        },
        hasImage() {
            if (!this.hasValidObjectId) return false
            return this.properties.objectId && this.imageLink.length > 0
        },
    },
    methods: {
        ...mapActions('iconLibrary', ['getSvgImage', 'setSvgColor', 'setSvgDimensions']),
        async loadSvg({ svgId, isInlineImage }) {
            await this.getSvgImage({ svgId, isInlineImage })

            this.adjustSvgDimensions(this.properties)
            this.updateColor(this.properties.color)
        },
        updateColor(color = null) {
            if (color) this.color = color
            if (!this.svg) return

            this.setSvgColor({ svgId: this.properties.objectId, color: this.color })
        },
        adjustSvgDimensions(resizeValues) {
            if (!this.svg || !resizeValues.width || !resizeValues.height) return
            this.setSvgDimensions({ svgId: this.properties.objectId, resizeValues })
            this.updateColor()
        },
        async saveColor(value) {
            try {
                await this.$store.dispatch('document/updateImage', {
                    entity: this.entityType,
                    id: this.properties.id,
                    color: value,
                    is_inline_image: this.is_inline_image,
                })

                await this.saveDocument()
            } catch (error) {
                throw error
            }
        },
        async rotateIcon() {
            try {
                if (!this.rotation || this.rotation >= 360) this.rotation = 0

                this.rotation = parseInt(this.rotation) + 45

                await this.$store.dispatch('document/updateImage', {
                    entity: this.entityType,
                    id: this.properties.id,
                    rotation: this.rotation,
                    is_inline_image: this.is_inline_image,
                })

                await this.saveDocument()
            } catch (error) {
                throw error
            }
        },
        async flipHorizontal() {
            try {
                await this.$store.dispatch('document/updateImage', {
                    entity: this.entityType,
                    id: this.properties.id,
                    flipHorizontal: !this.properties.flipHorizontal,
                    is_inline_image: this.is_inline_image,
                })

                this.flipImageHorizontally = !this.flipImageHorizontally
                await this.saveDocument()
            } catch (error) {
                throw error
            }
        },
        async flipVertical() {
            try {
                await this.$store.dispatch('document/updateImage', {
                    entity: this.entityType,
                    id: this.properties.id,
                    flipVertical: !this.properties.flipVertical,
                    is_inline_image: this.is_inline_image,
                })

                this.flipImageVertically = !this.flipImageVertically
                await this.saveDocument()
            } catch (error) {
                throw error
            }
        },
    },
}
