<template>
    <div
        v-if="!item.hide"
        class="d-block mb-0 basic-text"
        :style="{
            minHeight: style.font_space + 'em', //lineheight
            boxSizing: 'content-box',
        }"
        v-html="item.data.instruction"
    ></div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'BasicTextPreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            style: 'document/documentStyle',
        }),
    },
})
</script>
