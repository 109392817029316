<template>
    <div v-if="!item.hide" class="open-response mb-0 overflow-hidden" :class="{ show_answer_key: showAnswerKey }">
        <!-- Number and Question. -->
        <div
            class="d-inline-flex mb-0 w-100"
            :class="{
                'align-items-end': normalLinesWithAfter && !isQuestionMultiline && questionWidth() / fullPageWidth <= 0.85,
            }"
            :style="{
                minHeight: numberAndQuestionHeight,
            }"
        >
            <span
                v-if="item.numbering && item.numbering.number > 0"
                class="preview-number d-flex align-items-start"
                :class="{ 'number-hide': item.numbering.hidden }"
            >
                <NumberFormatIndex :index-info="item.numbering" resizable />
            </span>
            <span
                ref="question"
                class="mb-0 flex-shrink-0"
                style="max-width: 7.2in; overflow-wrap: break-word"
                v-html="item.data.subtitle"
            ></span>
            <div
                v-if="answerType === 'single' && item.data.line_position === 'after' && questionAndLine() <= fullPageWidth"
                class="d-flex flex-column justify-content-end ml-1 position-relative"
            >
                <AnswerSpan
                    v-if="showAnswerKey"
                    :answer="item.data.answer"
                    class="d-block text-center text-nowrap position-absolute inset-0"
                    :style="{ maxWidth: fullPageWidth - questionWidth() + 'in', marginBottom: `-0.1em !important` }"
                />
                <span
                    class="response-lines align-self-start"
                    :style="{
                        width: `${item.data.line_length}in`,
                    }"
                ></span>
            </div>
            <span
                v-if="normalLinesWithAfter && !isQuestionMultiline && questionWidth() / fullPageWidth <= 0.85"
                class="response-lines ml-1 position-relative"
                :style="{
                    width: `${fullPageWidth - questionWidth()}in`,
                    lineHeight: `${paragraphedAnsMarginTop + 0.1}in`,
                }"
            >
                <AnswerSpan
                    v-if="showAnswerKey"
                    :answer="linesAfterAnswers[0]"
                    class="d-flex align-items-end text-nowrap mb-3"
                    :style="{
                        maxWidth: `${fullPageWidth - questionWidth()}in`,
                        fontSize: `${computedLineHeight / 3}in`,
                    }"
                />
                <div :id="`paragraph-measure-${item.id}`" class="position-absolute inset-0 ml-4"></div>
            </span>
        </div>

        <!-- Answer Type: Short Line, But line and question won't fit in single line -->
        <div
            v-if="answerType === 'single' && (item.data.line_position === 'below' || questionAndLine() > fullPageWidth)"
            class="mx-3 ml-4"
        >
            <div
                class="response-lines overflow-hidden"
                :style="{
                    width: `${item.data.line_length}in`,
                    marginTop: `15px`,
                    minHeight: `calc(${style.font_space}em + 3px)`,
                }"
            >
                <AnswerSpan
                    v-if="showAnswerKey"
                    :answer="item.data.answer"
                    class="d-block text-left text-nowrap"
                    :style="{ minHeight: '1em' }"
                />
            </div>
        </div>

        <!-- Answer Type: Standard Lines -->
        <div v-if="answerType === 'standard_lines'" class="ml-4 position-relative overflow-hidden">
            <div
                v-for="(x, lineIndex) in parseInt(numberOfLines)"
                :key="lineIndex"
                :ref="`line-${item.id}-${lineIndex}`"
                class="response-lines"
                :style="{ marginTop: item.data.line_height + 'in !important' }"
            ></div>

            <ParagraphedAnswer
                v-if="showAnswerKey"
                class="position-absolute inset-0"
                :style="{
                    lineHeight: `${computedLineHeight}in !important`,
                    marginTop: `${paragraphedAnsMarginTop}in`,
                    fontSize: `${computedLineHeight / 3}in`,
                }"
                :answer="item.data.line_position === 'after' ? linesAfterAnswers[1] : item.data.answer"
            />
        </div>

        <!-- Answer Type: Handwriting Lines -->
        <div
            v-if="answerType === 'handwriting'"
            :id="`handwriting-response-${item.id}`"
            class="position-relative overflow-hidden"
        >
            <div
                v-for="(x, lineIndex) in parseInt(item.data.number_of_lines)"
                :key="lineIndex"
                :ref="`base-parent-line-${item.id}-${lineIndex}`"
                class="handwriting-practice-open-response mt-4 ml-4"
                :style="{
                    overflowX: 'clip',
                }"
            >
                <div
                    :ref="`line-${item.id}-${lineIndex}`"
                    class="handwriting position-relative d-flex align-content-end"
                    :style="{
                        fontSize: item.data.line_height + 'in',
                        marginBottom:
                            lineIndex + 1 === item.data.number_of_lines
                                ? parseFloat(item.data.line_height) * 0.25 + 'in'
                                : 'unset',
                    }"
                >
                    <div
                        v-if="showAnswerKey"
                        class="position-absolute inset-0 text-danger d-flex flex-column justify-content-end"
                        :style="{
                            fontSize: handwritingLineHeight * 0.75 + 'in',
                            paddingTop:
                                handwritingLineHeight * 0.25 -
                                convertPixelToInches(handWritingBordersPx + lineTopMargin) +
                                'in',
                            lineHeight: 0.8,
                        }"
                    >
                        <span>
                            {{ answerLines[lineIndex] ?? '' }}
                        </span>
                    </div>
                </div>
            </div>

            <div
                :id="`paragraph-measure-${item.id}`"
                class="position-absolute inset-0 ml-4"
                :style="{ fontSize: handwritingLineHeight * 0.75 + 'in', lineHeight: 0.8 }"
            ></div>
        </div>

        <!-- Answer Type: Blank -->
        <div v-if="answerType === 'blank'" class="mb-0">
            <!-- Add number spacing for answer key -->
            <span
                v-if="item.numbering && item.numbering.number > 0"
                class="preview-number d-flex align-items-top"
                :class="{ 'number-hide': true }"
            >
                <NumberFormatIndex :index-info="item.numbering" resizable />
            </span>
            <ParagraphedAnswer
                v-if="showAnswerKey"
                class="position-relative thisShow"
                :answer="item.data.answer || '-'"
                :style="{
                    lineHeight: blankFontSize + 0.1 + 'in',
                    fontSize: blankFontSize + 'in',
                    height: item.data.answer_height + 'in',
                    overflow: 'hidden',
                    display: `inline-table !important`,
                }"
                @height="(height) => (answerHeight = height)"
            >
                <figure
                    v-if="!isWidgetClosed"
                    class="mb-0 border-style"
                    :class="blankSpaceWithAnswerStyles"
                    :style="{
                        '--bgColor': bgColor,
                    }"
                >
                    <div
                        class="d-block position-absolute"
                        :class="bottomWithAnswerStyles"
                        :style="{
                            '--heightPseudo':
                                (parseFloat(item.data.answer_height) - convertPixelToInches(answerHeight)) / 5 + 'in',
                        }"
                    >
                        <img src="../../../img/icons/blank_bottom.svg" alt="" />
                    </div>
                </figure>
            </ParagraphedAnswer>

            <div
                v-else
                class="d-flex my-auto position-relative thisShow"
                :style="{
                    height: item.data.answer_height + 'in',
                    transition: 'height .5s ease-in-out',
                }"
            >
                <figure
                    class="mb-0 border-style"
                    :class="blankSpaceWithoutAnswerStyles"
                    :style="{
                        '--bgColor': bgColor,
                    }"
                >
                    <div
                        class="d-block position-absolute"
                        :class="bottomWithoutAnswerStyles"
                        :style="{ '--heightPseudo': item.data.answer_height / 5 + 'in' }"
                    >
                        <img src="../../../img/icons/blank_bottom.svg" alt="" />
                    </div>
                </figure>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import indexToLetter from '../../mixins/IndexToLetter'
import { mapGetters, mapState } from 'vuex'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'
import AnswerSpan from '../AnswerSpan.vue'
import ParagraphedAnswer from '../ParagraphedAnswer.vue'
import { fixUnmatchedClosingTags, fixUnmatchedOpeningTags } from '../../common/helpers'
import { stripHtmlTags } from '../../helpers/stringUtils'

export default defineComponent({
    name: 'OpenResponsePreview',
    components: { AnswerSpan, ParagraphedAnswer, NumberFormatIndex },
    mixins: [indexToLetter],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fullPageWidth: 7.2,
            index: 0,
            lineBorderHeight: 0,
            lineTopMargin: 0,
            handWritingBordersPx: 0,
            borderThickness: 0,
            lineFontSize: 14,
            bgColor: '#cacaca',
            answerHeight: 0,
        }
    },
    computed: {
        ...mapState(['document']),
        isQuestionMultiline() {
            return this.questionLines() > 1
        },
        numberOfLines() {
            if (
                !this.isQuestionMultiline &&
                this.normalLinesWithAfter &&
                this.questionWidth() / this.fullPageWidth <= 0.85
            ) {
                return this.item.data.number_of_lines - 1
            }

            return this.item.data.number_of_lines
        },
        ...mapGetters({
            style: 'document/documentStyle',
            globalShowAnswerKey: 'document/showAnswerKey',
        }),
        showAnswerKey() {
            return this.globalShowAnswerKey && this.item.data.has_answer
        },
        computedLineHeight() {
            const height = this.convertPixelToInches(parseFloat(this.lineBorderHeight))
            return parseFloat(this.item.data.line_height) + height
        },
        handwritingLineHeight() {
            const borders = this.convertPixelToInches(this.handWritingBordersPx)
            return parseFloat(this.item.data.line_height) - borders
        },
        answerLines() {
            const container = document.getElementById(`paragraph-measure-${this.item.id}`)
            let answer = this.answerType === 'handwriting' ? this.handwritingAnswer : this.item.data.answer
            if (!container || this.answerType != 'handwriting' || !answer) {
                return []
            }

            const words = answer.split(' ')
            const lines = []
            let line = ''

            const testElement = this.createTestElement(container)
            document.body.appendChild(testElement)

            words.forEach((word) => {
                const testLine = line + word + ' '
                testElement.innerHTML = testLine

                if (testElement.offsetWidth > container.offsetWidth) {
                    // If the test line width exceeds the container width, start a new line
                    if (line.trim()) {
                        lines.push(line.trim())
                    }
                    line = word + ' '
                } else if (testElement.offsetWidth === container.offsetWidth) {
                    // If the test line width exactly matches the container width, add the line and start a new one
                    lines.push(testLine.trim())
                    line = ''
                } else {
                    line = testLine
                }
            })

            // Add the last line
            lines.push(line.trim())
            document.body.removeChild(testElement)
            return lines
        },
        paragraphedAnsMarginTop() {
            return this.item.data.line_height - this.computedLineHeight / 1.4
        },
        blankFontSize() {
            return Math.min(this.item.data.answer_height, this.convertPixelToInches(this.style.font_size))
        },
        linesAfterAnswers() {
            const container = document.getElementById(`paragraph-measure-${this.item.id}`)
            let answer = this.item.data.answer
            if (!container || !this.isLinesAnswerFormat || !answer) {
                return ['', answer ?? '']
            }
            container.style.fontSize = `${this.computedLineHeight / 3}in`

            const words = answer.split(' ')
            const lines = []
            let line = ''

            // Create a test element to measure text
            const testElement = this.createTestElement(container)
            testElement.style.fontSize = `${this.computedLineHeight / 3}in`
            testElement.style.width = 'auto'
            document.body.appendChild(testElement)

            for (let index = 0; index < words.length; index++) {
                const testLine = line + words[index] + ' '
                testElement.innerHTML = testLine

                if (testElement.offsetWidth > container.offsetWidth) {
                    // If the test line width exceeds the container width, it's a new line
                    lines.push(fixUnmatchedOpeningTags(line.trim()))
                    lines.push(fixUnmatchedClosingTags(words.slice(index).join(' ')))
                    break
                } else {
                    line = testLine
                }
            }

            if (lines.length === 0) lines.push(line.trim())

            document.body.removeChild(testElement)
            return lines
        },
        normalLinesWithAfter() {
            return this.answerType === 'standard_lines' && this.item.data.line_position === 'after'
        },
        numberAndQuestionHeight() {
            return this.normalLinesWithAfter &&
                !this.isQuestionMultiline &&
                this.questionWidth() / this.fullPageWidth <= 0.85
                ? `${this.computedLineHeight / 2}in`
                : 'auto'
        },
        blankSpaceWithAnswerStyles() {
            let response = []
            const answer = this.convertPixelToInches(this.answerHeight)
            const blankSpaceHeight = parseFloat(this.item.data.answer_height) - answer

            if (!blankSpaceHeight || blankSpaceHeight <= 1) return

            if (this.document.is_published || this.isWidgetClosed) response.push('no-show')
            if (blankSpaceHeight <= 1) response.push('no-show')
            if (blankSpaceHeight <= 3) response.push('border-style')
            if (blankSpaceHeight > 3 && blankSpaceHeight <= 5) response.push('border-style-middle')
            if (blankSpaceHeight >= 5) response.push('border-style-large')

            return response
        },
        bottomWithAnswerStyles() {
            let response = []
            const answer = this.convertPixelToInches(this.answerHeight)
            const blankSpaceHeight = parseFloat(this.item.data.answer_height) - answer

            if (!blankSpaceHeight || blankSpaceHeight <= 0.1) return

            if (this.document.is_published || this.isWidgetClosed) response.push('no-show')
            if (blankSpaceHeight <= 1) response.push('no-show')
            if (blankSpaceHeight <= 3) response.push('addingBefore')
            if (blankSpaceHeight > 3 && blankSpaceHeight <= 5) response.push('addingAgainBefore')
            if (blankSpaceHeight >= 5) response.push('addingLastBefore')

            return response
        },
        blankSpaceWithoutAnswerStyles() {
            let response = []

            if (this.document.is_published || this.isWidgetClosed) response.push('no-show')
            if (this.item.data.answer_height <= 3) response.push('border-style')
            if (this.item.data.answer_height > 3 && this.item.data.answer_height <= 5) response.push('border-style-middle')
            if (this.item.data.answer_height >= 5) response.push('border-style-large')

            return response
        },
        bottomWithoutAnswerStyles() {
            let response = []

            if (this.document.is_published || this.isWidgetClosed) response.push('no-show')
            if (this.item.data.answer_height <= 1) response.push('no-show')
            if (this.item.data.answer_height <= 3) response.push('addingBefore')
            if (this.item.data.answer_height > 3 && this.item.data.answer_height <= 5) response.push('addingAgainBefore')
            if (this.item.data.answer_height >= 5) response.push('addingLastBefore')

            return response
        },
        isWidgetClosed() {
            return this.document.currentWidget?.focusedItem?.id !== this.item.id
        },
        isLinesAnswerFormat() {
            return ['standard_lines', 'handwriting'].includes(this.answerType)
        },
        handwritingAnswer() {
            return stripHtmlTags(this.item.data.handwritingAnswer || this.item.data.answer)
        },
        answerType() {
            if (this.item.data.answer_type === 'lines') {
                if (this.item.data.line_style === 'handwriting') return 'handwriting'
                else return 'standard_lines'
            }

            return this.item.data.answer_type
        },
    },
    watch: {
        answerLines: {
            handler() {
                this.setMultiLinesDivProperties()
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.setMultiLinesDivProperties()
    },
    methods: {
        questionLines() {
            let elHeight = this.$refs.question ? this.$refs.question.offsetHeight : 0

            let lineHeight = parseInt(this.style.font_space * this.style.font_size)

            return Math.floor(elHeight / lineHeight)
        },
        convertPixelToInches(pixel) {
            return pixel / 96 // 1in = 96px
        },
        questionWidth() {
            let inputText = this.item.data.subtitle ? this.item.data.subtitle.replace(/<\/?[^>]+(>|$)/g, '') : ''

            let context = document.createElement('canvas').getContext('2d')

            context.font = `${this.style.font_size}px ${this.style.font}`

            let width = Math.ceil(context.measureText(inputText).width)

            return this.convertPixelToInches(width).toFixed(1)
        },
        questionAndLine() {
            return parseFloat(this.questionWidth()) + parseFloat(this.item.data.line_length)
        },
        setMultiLinesDivProperties() {
            const firstDiv = this.$refs[`line-${this.item.id}-0`]
            const baseDiv = this.$refs[`base-parent-line-${this.item.id}-0`]

            if (firstDiv && firstDiv[0]) {
                const height = firstDiv[0].getBoundingClientRect().height
                const style = window.getComputedStyle(firstDiv[0])
                const marginTop = parseFloat(style.marginTop)
                this.lineBorderHeight = parseFloat(height)
                this.lineFontSize = style.getPropertyValue('font-size')

                //Factor in border thicknesses
                const borderTopWidth = parseInt(style.borderTopWidth)
                const borderBottomWidth = parseInt(style.borderBottomWidth)
                if (this.answerType === 'handwriting') {
                    if (baseDiv && baseDiv[0]) {
                        const parentStyle = window.getComputedStyle(baseDiv[0])
                        this.lineTopMargin = parseFloat(parentStyle.marginTop)
                    } else {
                        this.lineTopMargin = marginTop
                    }

                    const beforeElement = window.getComputedStyle(firstDiv[0], '::before')
                    const beforeBorderWidth =
                        parseInt(beforeElement.getPropertyValue('border-bottom-width')) +
                        parseInt(beforeElement.getPropertyValue('border-top-width'))
                    this.handWritingBordersPx = beforeBorderWidth + borderTopWidth + borderBottomWidth
                } else {
                    this.borderThickness = borderTopWidth + borderBottomWidth
                }
            }
        },
        createTestElement(container) {
            // Delete the test element if it exists
            if (document.getElementById('test-element')) {
                document.body.removeChild(document.getElementById('test-element'))
            }

            // Create a test element to measure text
            const testElement = document.createElement('div')
            testElement.id = 'test-element'
            testElement.style.position = 'absolute'
            testElement.style.bottom = '-9999px'
            testElement.style.whiteSpace = 'nowrap'
            testElement.style.width = container.getBoundingClientRect().width
            testElement.style.font = window.getComputedStyle(container).font

            return testElement
        },
    },
})
</script>

<style scoped>
.inset-0 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.border-style::before {
    content: '';
    border-left: 3px dashed var(--bgColor) !important;
    left: 0.7rem;
    background-image: url('../../../img/icons/blank_border.svg');
    background-repeat: no-repeat;
    position: absolute;
    display: flex;
    height: 100%;
    z-index: -10;
}

.border-style::after {
    content: '';

    transform: rotate(90deg);
    left: -1.7rem;
    width: 100px;
    display: flex;

    position: absolute;
    bottom: 1.5rem;
    height: 0;
    color: #cacaca;
    transition: all cubic-bezier(0, 0.81, 0.4, 1) 0.5s;
}
.border-style-middle::after {
    content: 'BLANK';

    transform: rotate(90deg);
    left: -1.7rem;
    width: 100px;
    display: flex;

    position: absolute;
    bottom: 5rem;
    height: 0;
    color: #cacaca;
    transition: all cubic-bezier(0, 0.81, 0.4, 1) 0.5s;
}
.border-style-large::after {
    content: 'BLANK SPACE';

    transform: rotate(90deg);
    left: -1.7rem;
    width: 100px;
    display: flex;

    position: absolute;
    bottom: 8.5rem;
    height: 0;
    color: #cacaca;
    transition: all cubic-bezier(0, 0.81, 0.4, 1) 0.5s;
}

.no-show {
    opacity: 0;
}

.addingBefore {
    bottom: -0.3rem;
}
.addingAgainBefore {
    bottom: -0.3rem;
}
.addingLastBefore {
    bottom: -0.3rem;
}

.addingBefore::after {
    content: '';
    position: absolute;
    background-image: url('../../../img/icons/blank_border.svg');
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: auto;

    left: 0.2rem;
    width: 25px;
    display: flex;

    bottom: 1rem;
    padding-top: 2rem;
    height: var(--heightPseudo);
}
.addingAgainBefore::after {
    content: '';
    position: absolute;
    background-image: url('../../../img/icons/blank_border.svg');
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: auto;

    left: 0.2rem;
    width: 25px;
    display: flex;

    bottom: 3rem;
    padding-top: 6rem;
    height: var(--heightPseudo);
}
.addingLastBefore::after {
    content: '';
    position: absolute;
    background-image: url('../../../img/icons/blank_border.svg');
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: auto;

    left: 0.2rem;
    width: 25px;
    display: flex;

    bottom: 3rem;
    padding-top: 9rem;
    height: var(--heightPseudo);
}
</style>
