<template>
    <div class="w-100 h-100 cursor-pointer">
        <div class="h-100 w-100">
            <div
                :id="`flashcard-item-${item.index}`"
                class="d-flex justify-content-center align-items-center flashcard-block h-100 w-100"
                :class="[textPlacementClass, alignmentClass]"
                :style="cardStyle"
            >
                <InlineImage
                    v-if="inlineImageId"
                    :ref="inlineImageId"
                    :key="inlineImageId"
                    :image-id="inlineImageId"
                    :size="style?.images?.size"
                    :max-size="maxImageHeight"
                />
                <FittyText v-if="hasText" :text="text"></FittyText>
            </div>
        </div>
    </div>
</template>

<script>
import FittyText from './FittyText.vue'
import InlineImage from './widgets/InlineImage.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'FlashcardItemPreview',
    components: {
        FittyText,
        InlineImage,
    },
    props: {
        item: {
            type: Object,
            default: undefined,
        },
        type: {
            type: String,
            default: '',
        },
        pageIndex: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            pageSetup: 'document/pageSetup',
            style: 'document/documentStyle',
            inlineImages: 'document/flashcardInlineImages',
            flashcardItemHeight: 'document/flashcardItemHeight',
        }),
        cardStyle() {
            if (this.pageSetup?.flipcard_type === 'vertical') {
                if (this.pageIndex % 2 === 1 || this.type === 'definition') {
                    return { transform: `rotate(180deg)` }
                }
            }
            return {}
        },
        text() {
            return this.type === 'term' ? this.item.term : this.item.definition
        },
        inlineImageId() {
            return this.type === 'term' ? this.item?.term_image : this.item?.definition_image
        },
        textPlacementClass() {
            return this.style?.images?.alignment !== 'center'
                ? ''
                : this.style?.images?.text_placement === 'below'
                  ? 'flex-column'
                  : 'flex-column-reverse'
        },
        alignmentClass() {
            return this.style?.images?.alignment === 'center'
                ? ''
                : this.style?.images?.alignment === 'start'
                  ? 'flex-row'
                  : 'flex-row-reverse'
        },
        hasText() {
            return this.text && this.text.length
        },
        maxImageHeight() {
            const textHeight = this.getFontHeightUsingCanvas
            const itemHeight = this.flashcardItemHeight * 96
            return this.style?.images?.alignment === 'center' && this.hasText ? itemHeight - textHeight : itemHeight
        },
        getFontHeightUsingCanvas() {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            context.font = `${this.style.font_size}px ${this.style.font}`

            const metrics = context.measureText('Hg')
            const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent

            return actualHeight
        },
    },
}
</script>

<style>
svg {
    vertical-align: inherit;
}
</style>
