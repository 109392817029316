import { isNumber } from 'lodash'
import { documentIsSmallerThanWorkspace } from '../store/modules/reusable/zoom'

export default {
    methods: {
        resizeDocument(event = null) {
            if (this.document.entity_type === 'flashcard' && this.document.entity.page_setup.flashcard_type === '3x5') {
                this.mediumWidth = 1550
            }

            let innerWidth = event ? event.target.innerWidth : window.document.documentElement.clientWidth
            let container = document.getElementById('workspaceContainer')
            let rightPadding = parseFloat(window.getComputedStyle(container).paddingRight)

            //Allow rescale and manuallyResizedUp could be combined in future.
            if (this.document.zoom == 'Fit') {
                if (!this.document.manuallyResizedUp && this.mediumWidth + rightPadding < innerWidth && this.allowRescale) {
                    //
                    this.$store.dispatch('document/setZoom', '100%')
                } else if (this.mediumWidth + rightPadding > innerWidth) {
                    this.allowRescale = true
                    this.$store.dispatch('document/setManualResize', false)
                }
                this.$store.dispatch('document/scaleDocument', true)
            } else if (
                this.document.zoom !== 1 &&
                this.mediumWidth < innerWidth &&
                !this.resizedUp &&
                !this.document.manuallyResizedUp
            ) {
                this.allowRescale = false
                if (!(isNumber(this.document.zoom) && this.document.zoom < 1)) {
                    this.$store.dispatch('document/setZoom', 'Fit')
                }
            } else if (this.mediumWidth > innerWidth || (this.mediumWidth < innerWidth && this.document.manuallyResizedUp)) {
                if (this.isDocumentHidden('workspace', 'workspaceContainer')) {
                    this.allowRescale = true
                    this.$store.dispatch('document/setZoom', 'Fit')
                }
            } else if (this.document.zoom == 1 && !documentIsSmallerThanWorkspace(1)) {
                this.$store.dispatch('document/setZoom', 'Fit')
                this.allowRescale = true
            }
            this.workspaceWidthCalculation()
        },
        workspaceWidthCalculation() {
            setTimeout(() => {
                this.workspaceWidth = document.getElementById('workspace').getBoundingClientRect().right
            }, 10)
        },
    },
}
