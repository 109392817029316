<template>
    <div v-if="!item.hide" class="matching-preview mb-0">
        <div v-if="item.numbering && item.numbering.number > 0" class="d-inline-flex mb-0">
            <span
                class="preview-number d-flex align-items-top"
                v-html="
                    $store.getters['document/format_number'](
                        (item.numbering && item.numbering.number) || 1,
                        item.numbering && item.numbering.format,
                    )
                "
            ></span>
            <span class="mb-0" v-html="item.data.subtitle"></span>
        </div>
        <div
            v-cloak
            ref="matching-container"
            v-observe-visibility="visibilityChanged"
            class="matching-container columns pt-4 pl-3 pr-3"
            :style="{
                borderWidth: `${item.style.border_width}px`,
                borderStyle: item.style.border_style,
                borderColor: item.style.border_color,
                fontSize: `${item.style.font_size}px`,
            }"
        >
            <div
                ref="termColumn"
                class="column"
                :style="{ maxWidth: item.data.settings.match_type === 'line' ? '250px' : '50%' }"
            >
                <div
                    v-for="(mItem, index) in item.data.pairs"
                    :key="`item-${index}`"
                    :ref="`item-${index}`"
                    class="item d-flex align-items-center"
                    :style="{
                        textAlign: item.data.settings.align_clues,
                    }"
                >
                    <!-- Question number -->

                    <span v-if="item.data.settings.display_index" class="number">
                        <NumberFormatIndex
                            :index-info="{ number: index + 1, format: leftColumnFormat }"
                            :custom-size="item.style.font_size"
                        />
                    </span>
                    <div
                        class="d-flex"
                        :class="[alignNumberingClass, alignDotsClass, alignLineClass, alignItemsWithoutHorizontalAlignment]"
                    >
                        <!-- blank before -->
                        <span
                            v-if="showBlankBeforeTerm"
                            class="answer margin-right"
                            :class="document.show_answer_key ? 'text-answer' : ''"
                            :style="{ borderColor: item.style.color }"
                        >
                            <span class="text-pre letterAnswer" :class="{ hideAnswers: !document.show_answer_key }">
                                {{ getAnswer(index) }}
                            </span>
                        </span>

                        <!-- term, possibly scrambled if desired -->
                        <div class="d-flex" :class="alignItemsWithHorizontalAlignment">
                            <div
                                class="d-flex justify-content-center align-items-center"
                                :class="textPlacementClass"
                                @click="handleTerm(index)"
                            >
                                <InlineImage
                                    v-if="mItem?.term_image?.length"
                                    :image-id="mItem.term_image"
                                    :size="item.data.settings?.images?.size"
                                />
                                <div>
                                    <span
                                        class="term text-pre"
                                        style="word-break: break-word"
                                        v-text="
                                            item.data.settings.word_scramble.has_word_scramble &&
                                            item.data.settings.word_scramble.scramble_left
                                                ? scrambleWord(mItem.term, index + 1)
                                                : mItem.term
                                        "
                                    ></span>
                                </div>
                            </div>
                        </div>

                        <!-- blank after -->
                        <span
                            v-if="showBlankAfterTerm"
                            class="answer margin-left"
                            :class="document.show_answer_key ? 'text-answer' : ''"
                            :style="{ borderColor: item.style.color }"
                        >
                            <span class="text-pre letterAnswer" :class="{ hideAnswers: !document.show_answer_key }">
                                {{ getAnswer(index) }}
                            </span>
                        </span>

                        <!-- dot for drawing lines -->
                        <span
                            v-show="showDots"
                            :ref="`question-${index}`"
                            class="dot"
                            :class="{ 'opacity-0': !item.data.settings.show_dots_left }"
                            :style="dotStyle"
                        ></span>
                    </div>
                </div>
            </div>

            <div
                ref="definitionColumn"
                class="column"
                :style="{ maxWidth: item.data.settings.match_type === 'line' ? '270px' : '50%' }"
            >
                <div
                    v-for="(sItem, index) in shuffled"
                    :key="'matching-items-' + index"
                    :ref="`matching-items-${index}`"
                    class="item d-flex align-items-center"
                >
                    <span
                        v-if="showDots"
                        :ref="'answer-' + index"
                        class="dot"
                        :class="{ 'opacity-0': !item.data.settings.show_dots_right }"
                        :style="dotStyle"
                    ></span>

                    <!-- Answer Letter NOTE:: if they are using blanks for matching, letters MUST be displayed -->
                    <span v-if="item.data.settings.display_index" class="number">
                        <NumberFormatIndex
                            :index-info="{ number: index + 1, format: rightColumnFormat }"
                            :custom-size="item.style.font_size"
                        />
                    </span>

                    <div class="d-flex align-items-start">
                        <div
                            class="d-flex justify-content-center align-items-center"
                            :class="textPlacementClass"
                            @click="getDefinition(sItem)"
                        >
                            <InlineImage
                                v-if="sItem?.definition_image?.length"
                                :image-id="sItem.definition_image"
                                :size="item.data.settings?.images?.size"
                            />
                            <span
                                class="definition text-pre"
                                style="word-break: break-word"
                                v-text="
                                    item.data.settings.word_scramble.has_word_scramble &&
                                    item.data.settings.word_scramble.scramble_right
                                        ? scrambleWord(sItem.definition, index + 1)
                                        : sItem.definition
                                "
                            ></span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.data.settings.match_type === 'line' && document.show_answer_key" ref="lines" class="lines"></div>
        </div>
    </div>
</template>

<script>
import Vue, { defineComponent } from 'vue'
import Chance from 'chance'
import range from 'lodash.range'
import { ResizeObserver } from 'resize-observer'
import { mapGetters, mapState } from 'vuex'
import indexToLetter from '../../mixins/IndexToLetter'
import VueObserveVisibility from 'vue-observe-visibility'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'
import InlineImage from '../widgets/InlineImage.vue'
import InlineImagesMixin from '../../mixins/InlineImages'
import { defaultLetteringFormat, defaultNumberingFormat, noneFormatOption } from '../../objects/NumberingFormat'

Vue.use(VueObserveVisibility)

export default defineComponent({
    name: 'MatchingPreview',
    components: {
        InlineImage,
        NumberFormatIndex,
    },
    mixins: [indexToLetter, InlineImagesMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isVisible: false,
            loadingHeights: false,
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            numbersAreVisible: 'document/numbersAreVisible',
            canBeShuffled: 'document/canBeShuffled',
            worksheet: 'document/worksheet',
            style: 'document/documentStyle',
            currentWidget: 'document/currentWidget',
            inlineImages: 'document/worksheetInlineImages',
        }),
        shuffled() {
            let items = this.getAnswerArray()
            let c = new Chance(this.item.data.shuffle_seed)
            return c.shuffle(items)
        },
        loadCompleted() {
            return {
                fonts: this.$fonts.ready,
            }
        },
        leftColumnFormat() {
            return this.item.data.settings.left_column_format || Number(defaultNumberingFormat.value)
        },
        rightColumnFormat() {
            return (
                this.item.data.settings[this.rightColumnKey] ||
                (this.isMatchTypeLetters ? Number(defaultLetteringFormat.value) : Number(noneFormatOption.value))
            )
        },
        alignItemsWithHorizontalAlignment() {
            if (
                (this.item.data.settings.match_type === 'line' && !this.item.data.settings.align_dots_horizontally) ||
                (this.item.data.settings.match_type === 'letter' && !this.item.data.settings.align_line_horizontally)
            )
                return
            return `w-100 justify-content-${this.item.data.settings.images?.alignment || 'start'}`
        },
        alignItemsWithoutHorizontalAlignment() {
            return `w-100 justify-content-${this.item.data.settings.images?.alignment || 'start'}`
        },
        alignNumberingClass() {
            return this.item.data.settings.match_type === 'letter' ? 'align-items-start' : 'align-items-center'
        },
        alignDotsClass() {
            if (this.item.data.settings.match_type !== 'line') return ''

            let classes = ''

            if (this.item.data.settings.align_dots_vertically) {
                classes += 'align-items-center '
            }

            if (this.item.data.settings.align_dots_horizontally) {
                classes += 'w-100 justify-content-between'
            }

            return classes.trim()
        },
        alignLineClass() {
            if (this.item.data.settings.match_type !== 'letter') return ''

            return this.item.data.settings.align_line_horizontally
                ? `w-100 justify-content-${this.item.data.settings.images?.alignment}`
                : ''
        },
        showBlankBeforeTerm() {
            return this.item.data.settings.match_type === 'letter' && this.item.data.settings.blank_position === 'before'
        },
        showBlankAfterTerm() {
            return this.item.data.settings.match_type === 'letter' && this.item.data.settings.blank_position === 'after'
        },
        textPlacementClass() {
            return this.item.data.settings?.images?.text_placement === 'below' ? 'flex-column' : 'flex-column-reverse'
        },
        showDots() {
            return this.item.data.settings.match_type === 'line'
        },
        isMatchTypeLetters() {
            return this.item.data.settings.match_type === 'letter'
        },
        rightColumnKey() {
            return this.isMatchTypeLetters ? 'letter_right_column_format' : 'right_column_format'
        },
        dotStyle() {
            const fontSize = parseInt(this.item.style.font_size)
            const dotSize = Math.max(14, Math.min(24, fontSize))

            return {
                backgroundColor: this.item.style.color,
                fontSize: `${dotSize}px`,
            }
        },
    },
    watch: {
        loadCompleted: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.drawLines()
                })
            },
        },
        item: {
            deep: true,
            handler() {
                this.$nextTick(async () => {
                    this.item.data.settings.word_scramble ||
                        (await this.$store.dispatch('document/updateMatchingSettings', this.item))
                    this.drawLines()
                })
            },
        },
        style: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.drawLines()
                })
            },
        },
    },
    async created() {
        this.item.data.settings.word_scramble || (await this.$store.dispatch('document/updateMatchingSettings', this.item))
    },
    mounted() {
        this.$nextTick(() => {
            const ro = new ResizeObserver(() => this.drawLines())
            if (this.$refs.definitionColumn) ro.observe(this.$refs.definitionColumn)
            if (this.$refs.termColumn) ro.observe(this.$refs.termColumn)
        })
    },
    updated() {
        this.$nextTick(() => {
            this.drawLines()
            // this.handleVerticalAlignment()
        })
    },
    methods: {
        visibilityChanged(isVisible) {
            this.isVisible = isVisible
            this.drawLines()
        },
        getAnswerArray() {
            let answerArray = []

            if (this.item.data.pairs) {
                for (let i = 0; i < this.item.data.pairs.length; i++) {
                    answerArray.push({
                        term: this.item.data.pairs[i].term,
                        definition: this.item.data.pairs[i].definition,
                        fake: false,
                        index: i,
                        term_image: this.item.data.pairs[i].term_image,
                        definition_image: this.item.data.pairs[i].definition_image,
                    })
                }
            }
            if (this.item.data.fakeAnswers.length) {
                for (let i = 0; i < this.item.data.fakeAnswers.length; i++) {
                    answerArray.push({
                        definition: this.item.data.fakeAnswers[i].text,
                        definition_image: this.item.data.fakeAnswers[i].image,
                        fake: true,
                        index: i,
                    })
                }
            }

            return answerArray
        },
        drawLines() {
            if (this.document.show_answer_key && this.showDots && this.$refs.lines) {
                let wrapper = this.$refs['matching-container']

                //remove any existing children from the node
                while (this.$refs.lines.hasChildNodes()) {
                    this.$refs.lines.removeChild(this.$refs.lines.firstChild)
                }

                //create a canvas
                const canvas = document.createElement('canvas')
                canvas.width = wrapper.offsetWidth * 2
                canvas.height = wrapper.offsetHeight * 2
                canvas.style.width = `${wrapper.offsetWidth}px`
                canvas.style.height = `${wrapper.offsetHeight}px`
                canvas.style.position = 'absolute'
                canvas.style.top = '0'
                canvas.style.left = '0'
                canvas.font = '13px OpenSans'

                //append it!
                this.$refs.lines.appendChild(canvas)

                const context = canvas.getContext('2d')

                let items = this.item.data.pairs
                for (let i = 0; i < items.length; i++) {
                    let start = { x: 1, y: 1 }
                    let end = { x: 1, y: 1 }
                    let q = this.$refs[`question-${i}`][0]
                    if (q) {
                        start = {
                            x: q.offsetLeft * 2 + q.offsetWidth * 2,
                            y: Math.max(1, q.offsetTop * 2 + q.offsetHeight),
                        }
                    }

                    let keys = this.getKeyShuffle()
                    let a = this.$refs[`answer-${keys.indexOf(i)}`]
                    if (a) a = a[0]
                    if (a) {
                        end = {
                            x: a.offsetLeft * 2,
                            y: Math.max(1, a.offsetTop * 2 + a.offsetHeight / 2),
                        }
                    }
                    if (context) {
                        // draw the line
                        context.beginPath()
                        context.lineWidth = this.item.style.font_size / 2
                        context.strokeStyle = '#dc3545'
                        context.moveTo(start.x - context.lineWidth, start.y)
                        context.lineTo(end.x + context.lineWidth, end.y + context.lineWidth)
                        context.stroke()
                    }
                }
            }
        },
        getKeyShuffle() {
            // get our shuffler
            let c = new Chance(this.item.data.shuffle_seed)

            //recreate the keys from our array
            let keys = range(0, this.item.data.pairs.length + this.item.data.fakeAnswers.length)

            //shuffle those keys with the same seed
            return c.shuffle(keys)
        },
        getAnswer(val) {
            let keyShuffle = this.getKeyShuffle()
            //return the answer value
            return this.indexToLetter(keyShuffle.indexOf(val))
        },
        scrambleWord(word, seed) {
            if (!seed) {
                seed = 1
            }

            if (!this.canBeShuffled(word)) {
                // can't scramble a short word!
                return word?.toLowerCase().replace(/ /g, '_')
            }

            let newWord = word
            do {
                let c = new Chance(seed)
                const letters = word.split('')
                newWord = c.shuffle(letters).join('')
                seed++
            } while (newWord === word)

            return newWord.toLowerCase().replace(/ /g, '_')
        },
        async getDefinition(item) {
            this.currentWidget.focusedItem = this.item
            this.currentWidget.focusedItem.data.index = item.index
            this.currentWidget.focusedItem.data.fake = item.fake
            this.currentWidget.focusedItem.data.target = 'definition'
            await this.$store.dispatch('document/setWidgetStatus', this.currentWidget)
        },
        async handleTerm(index) {
            this.currentWidget.focusedItem = this.item
            this.currentWidget.focusedItem.data.index = index
            this.currentWidget.focusedItem.data.target = 'term'
            await this.$store.dispatch('document/setWidgetStatus', this.currentWidget)
        },
        // async handleVerticalAlignment() {
        //     if (this.loadingHeights) return
        //
        //     this.loadingHeights = true
        //     if (!this.item.data.settings.align_dots_vertically) {
        //         await this.clearPairHeights()
        //         return
        //     }
        //
        //     for (let i = 0; i < this.item.data.pairs.length; i++) {
        //         const termItem = this.$refs[`item-${i}`][0]
        //         const definitionItem = this.$refs[`matching-items-${i}`][0]
        //
        //         if (!termItem || !definitionItem) {
        //             this.loadingHeights = false
        //             return
        //         }
        //
        //         const pair = {
        //             item_id: this.item.id,
        //             pair_index: i,
        //             pair_height:
        //                 termItem.offsetHeight >= definitionItem.offsetHeight
        //                     ? termItem.offsetHeight
        //                     : definitionItem.offsetHeight,
        //         }
        //         console.log(pair)
        //         console.log(i)
        //         await this.$store.dispatch('document/setMatchingPairHeight', pair)
        //     }
        //     this.loadingHeights = false
        // },
        // async clearPairHeights() {
        //     for (let i = 0; i < this.item.data.pairs.length; i++) {
        //         const pair = {
        //             item_id: this.item.id,
        //             pair_index: i,
        //             pair_height: null,
        //         }
        //
        //         await this.$store.dispatch('document/setMatchingPairHeight', pair)
        //     }
        // },
    },
})
</script>

<style lang="scss" scoped>
[v-cloak] {
    display: none;
}

.matching-preview {
    .matching-container {
        border: 1px solid #212121;
        border-radius: 3px;
    }

    .number {
        align-self: stretch;
        display: flex;
        align-items: baseline;
    }
}

.opacity-0 {
    opacity: 0;
}
</style>
