export default {
    methods: {
        widthOfText(pText, multiple = 1, pFontSize = 1) {
            var lDiv = document.createElement('div')

            document.body.appendChild(lDiv)

            lDiv.style.fontSize = pFontSize + 'rem'
            lDiv.style.position = 'absolute'
            lDiv.style.left = -1000
            lDiv.style.top = -1000

            lDiv.textContent = pText

            var lResult = lDiv.clientWidth

            document.body.removeChild(lDiv)
            lDiv = null

            return lResult * multiple + 'px'
        },
    },
}
