export default {
    methods: {
        indexToLetter(index) {
            let letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

            if (index == 0) return 'A'

            // if there are more than 26 answers, we'll need AA, BB etc.
            let quotient = Math.max(1, Math.floor(index / 26)) // must be at least 1
            let letter = letters[index % 26]
            return letter.repeat(quotient)
        },
    },
}
