import Vue from 'vue'
import Vuex from 'vuex'
import { initialiseStores } from '../utils/store-accessor'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [(store) => initialiseStores(store)],
})

export * from '../utils/store-accessor'
export default store
