<template>
    <div v-if="!item.hide" class="multiple-choice d-flex">
        <div class="d-flex">
            <span
                v-if="item.numbering && item.numbering.number > 0"
                class="preview-number d-flex align-items-start"
                :class="{ 'number-hide': item.numbering.hidden }"
            >
                <NumberFormatIndex :index-info="item.numbering" resizable />
            </span>
        </div>

        <div>
            <div
                v-if="item.data.direction"
                class="direction text-justify"
                :style="{
                    minHeight: `calc(${style.font_space}em + 0.2em) !important`,
                }"
                v-html="item.data.subtitle"
            ></div>
            <div class="choices" :class="['grid-' + item.data.number_of_columns, !item.data.direction ? '' : 'mt-2']">
                <div
                    v-for="(option, index) in item.data.options"
                    :key="`option-${index}`"
                    class="choice checklist d-flex align-items-start"
                    :style="{
                        lineHeight: style.font_space + 'em',
                    }"
                    @click="optionItemClicked(index)"
                >
                    <div class="h-100">
                        <div
                            class="d-flex align-items-center justify-content-center"
                            :style="{
                                fontSize: style.font_size + 'px',
                                height: style.font_space + 'em',
                            }"
                        >
                            <NumberFormatIndex :index-info="{ number: index + 1, format: listFormat }" resizable />
                        </div>
                    </div>
                    <div class="p-0 d-flex align-items-start ml-1" style="word-break: break-word">
                        <span>{{ option.answer }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import indexToLetter from '../../mixins/IndexToLetter'
import { mapGetters, mapState } from 'vuex'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'

export default defineComponent({
    name: 'ChecklistPreview',
    components: { NumberFormatIndex },
    mixins: [indexToLetter],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            style: 'document/documentStyle',
        }),
        listFormat() {
            return this.item.data.settings?.format || 32
        },
    },
    methods: {
        optionItemClicked(index) {
            this.$emit('optionItemClicked', { ...this.item, ...{ clickedIndex: index } })
        },
    },
})
</script>

<style lang="scss" scoped>
.correct-answer-marker {
    border-radius: 50%;
    border: 1px solid transparent;
    height: 100%;
    width: 1.5em;
}

.choice {
    padding-bottom: 0.5em;
}

.choices input[type='checkbox'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    border: 0.13em solid currentColor;
    border-radius: 0.15em;
    cursor: pointer;
}

.choice.checklist:not(:last-child) {
    margin-bottom: 4px;
}

.direction p {
    margin-bottom: 0;
}
</style>
